import Waiting from 'components/Waiting/Waiting'
import { ReactElement } from 'react'
import Dropzone, { Accept, FileRejection } from 'react-dropzone'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import { useAppDispatch } from 'hooks'
import { setSnackbar } from 'reducers/feedback/feedback.reducers'
import { SnackbarSeverity } from 'reducers/feedback/types'
import terms from 'common/terms'

import './CustomDropZone.scss'

interface Props {
  isUploading: boolean
  onDropAccepted: (File: File[]) => void
  acceptedTypes: Accept
  maxFiles: number
  className?: string
  fileSelected: File | null
  setFileSelected: (File: File | null) => void
  label: string
  subLabel?: string
  fullTheme?: boolean
  variant?: 'blue' | 'purple' | ''
  imageMode?: boolean

}

const CustomDropZone = ({
  isUploading,
  onDropAccepted,
  acceptedTypes,
  maxFiles,
  className,
  fileSelected,
  setFileSelected,
  label,
  subLabel,
  fullTheme,
  variant,
  imageMode,
}: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const dropzoneClassName = `dropzone ${isUploading ? 'pending' : ''} ${(fileSelected) ? `has-files ${variant}` : ''}`
  const onDropRejected = (reject: FileRejection[]) => {
    switch (reject[0].errors[0].code) {
      case 'file-invalid-type':
        dispatch(setSnackbar({
          severity: SnackbarSeverity.ERROR,
          message: terms.Document.Upload.error.invalidType,
        }))
        break
      case 'too-many-files':
        dispatch(setSnackbar({
          severity: SnackbarSeverity.ERROR,
          message: terms.Document.Upload.error.tooManyFiles,
        }))
        break
      default:
        dispatch(setSnackbar({
          severity: SnackbarSeverity.ERROR,
          message: terms.Common.errorMessages.internalServerError,
        }))
    }
  }
  return (
    <div className={`custom-drop-zone ${className}`}>
      {isUploading && (
        <div className="feedback">
          {
            fullTheme && (
              <>
                <span>{terms.Document.Upload.uploadPending.title}</span>
                <span>{terms.Document.Upload.uploadPending.info}</span>
                <span>{terms.Document.Upload.uploadPending.message}</span>
              </>
            )
          }
          <Waiting />
        </div>
      )}
      <Dropzone
        maxFiles={maxFiles}
        disabled={isUploading}
        onDropRejected={onDropRejected}
        onDropAccepted={onDropAccepted}
        accept={acceptedTypes}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: dropzoneClassName })}>
            <input {...getInputProps()} />
            <FileDownloadOutlinedIcon className="icon" />
            <p>{label}</p>
            {
              subLabel && (
                <p>{subLabel}</p>
              )
            }
          </div>
        )}
      </Dropzone>
      {
        fileSelected && (
          <div className="file">
            {
              imageMode && fileSelected.type.startsWith('image/') && (
                <img src={URL.createObjectURL(fileSelected)} alt="preview" />
              )
            }
            {
              imageMode && fileSelected.type.startsWith('video/') && (
                <video controls height="300px">
                  <source src={URL.createObjectURL(fileSelected)} type={fileSelected.type} />
                  {terms.Common.errorMessages.videoTag}
                  <track kind="captions" srcLang="fr" label="Français" />
                </video>
              )
            }
            <div>
              <span>
                {fileSelected?.name}
              </span>
              <button
                disabled={isUploading}
                type="button"
                onClick={() => setFileSelected(null)}
              >
                <ClearOutlinedIcon className="delete" />
              </button>
            </div>
          </div>
        )
      }
    </div>
  )
}

CustomDropZone.defaultProps = {
  className: '',
  subLabel: '',
  fullTheme: false,
  variant: '',
  imageMode: false,
}

export default CustomDropZone
