import { ReactElement } from 'react'
import { DocumentItem, DocumentTraitementStatus } from 'reducers/documents/types'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { useAppDispatch } from 'hooks'
import { deleteDocument, getDocumentById } from 'reducers/documents/document.thunk'
import { setSnackbar } from 'reducers/feedback/feedback.reducers'
import terms from 'common/terms'
import { SnackbarSeverity } from 'reducers/feedback/types'
import { TooltipLight } from 'components/Theme/TooltipLight'

interface Props {
  document: DocumentItem
  deletable: boolean
}

const Document = ({ document, deletable }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const handleClick = (id: number) => {
    dispatch(getDocumentById(id)).then(res => {
      if (res.payload) {
        window.open(res.payload.url, '_blank')
        dispatch(setSnackbar({
          severity: SnackbarSeverity.SUCCESS,
          message: terms.Document.Snackbar.downloadSuccess,
        }))
      }
    })
  }

  const status = () => {
    switch (document.processingStatus) {
      case DocumentTraitementStatus.PENDING:
        return {
          libelle: terms.Document.Status.PENDING,
          color: 'grey', // grey pending
        }
      case DocumentTraitementStatus.STARTED:
        return {
          libelle: terms.Document.Status.STARTED,
          color: '#ee9207', // orange pending
        }
      case DocumentTraitementStatus.FAILURE:
        return {
          libelle: terms.Document.Status.FAILURE,
          color: '#eb604d', // red failed
        }
      case DocumentTraitementStatus.SUCCESS:
        return {
          libelle: terms.Document.Status.SUCCESS,
          color: 'green', // green completed
        }
      default:
        return {
          libelle: '',
          color: 'white',
        }
    }
  }

  const handleDelete = (id: number) => {
    dispatch(deleteDocument(id))
  }
  return (
    <div className="document-wrapper">
      <TooltipLight
        title={status().libelle}
        placement="top"
      >
        <span
          className="status"
          style={{
            backgroundColor: status().color,
            borderColor: status().color,
          }}
        />
      </TooltipLight>
      <div className="document">
        <button
          type="button"
          onClick={() => handleClick(document.id)}
        >
          <span className="name">
            {document.filename}
          </span>
        </button>
        {
          deletable && (
            <button
              type="button"
              onClick={() => handleDelete(document.id)}
              className="delete-button"
            >
              <DeleteForeverIcon />
            </button>
          )
        }
      </div>
    </div>
  )
}

export default Document
