import terms from 'common/terms'
import CustomButton, { ButtonVariant } from 'components/CustomButton/CustomButton'
import ModalWrapper from 'components/ModalWrapper/ModalWrapper'
import Search from 'components/Search/Search'
import { useAppDispatch, useAppSelector } from 'hooks'
import {
  ReactElement, useCallback, useState,
} from 'react'
import { getAllDocuments } from 'reducers/documents/document.thunk'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import { DocumentTypes } from 'reducers/documents/types'
import { resetDocumentList } from 'reducers/documents/document.reducers'
import CustomPagination from 'components/CustomPagination/CustomPagination'
import CircularProgress from '@mui/material/CircularProgress'
import { Backdrop } from '@mui/material'
import { debounce } from 'lodash'
import Document from './Document'
import DocumentMode from './DocumentMode/DocumentMode'
import './DocumentList.scss'

interface Props {
  open: boolean
  handleClose: () => void
  setOpenUploadDocument: () => void
  docMode: DocumentTypes
  setDocMode: (mode: DocumentTypes) => void
}

const DocumentList = ({
  open, handleClose, setOpenUploadDocument, docMode, setDocMode,
}: Props): ReactElement => {
  const dispatch = useAppDispatch()

  const PAGE_SIZE = 100
  const INITIAL_PAGE = 1

  const { documentList, isLoading } = useAppSelector(state => state.document)
  const [page, setPage] = useState<number>(INITIAL_PAGE)
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearch = useCallback(debounce(value => dispatch(getAllDocuments(value)), 250), [])

  const handleSearch = (val: string, currentMode: DocumentTypes) => {
    dispatch(resetDocumentList())
    if (val) {
      debouncedSearch({
        page: INITIAL_PAGE,
        source: currentMode,
        search: val,
      })
    } else {
      dispatch(getAllDocuments({
        page: INITIAL_PAGE,
        source: currentMode,
      }))
    }

    if (currentMode !== docMode) {
      setSearchValue('')
      setDocMode(currentMode)
    }
  }

  const handleClickUploadDocument = () => {
    setOpenUploadDocument()
    handleClose()
  }

  const handleChangePage = (val: number) => {
    setPage(val)
    dispatch(getAllDocuments({
      page: val,
      source: docMode,
    }))
  }

  return (
    <ModalWrapper
      title={`${terms.Document.List.title} (${documentList.count})`}
      className="document-list"
      open={open}
      handleClose={handleClose}
    >
      <div className="documents-wrapper">
        <DocumentMode mode={docMode} handleClickMode={handleSearch} />
        <div>
          <Search
            placeholder={terms.Document.Search.placeholder}
            handleSearch={handleSearch}
            size="small"
            mode={docMode}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
          <div className="documents">
            {documentList.results.map(document => (
              <Document
                key={document.id}
                document={document}
                deletable={docMode === DocumentTypes.myDocuments}
              />
            ))}
          </div>
        </div>
        <div className="pagination">
          {
            (documentList.count / PAGE_SIZE) > 1 && (
              <CustomPagination
                count={Math.ceil(documentList.count / PAGE_SIZE)}
                handleChange={handleChangePage}
                page={page}
              />
            )
          }
        </div>
        <div className="button-upload">
          <CustomButton
            title={terms.Sidebar.Buttons.uploadDocument}
            className="button"
            variant={ButtonVariant.secondary}
            handleClick={handleClickUploadDocument}
            icon={<CloudUploadOutlinedIcon />}
          />
        </div>
      </div>
      <Backdrop
        className="backdrop"
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </ModalWrapper>
  )
}

export default DocumentList
