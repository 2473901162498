import { ReactElement, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks'
import MessageList from 'components/MessageList/MessageList'
import {
  controller,
  createChat, getChatList, getTags, sendMessage,
} from 'reducers/chat/chat.thunk'
import Waiting from 'components/Waiting/Waiting'
import SendInput from 'components/SendInput/SendInput'
import Sidebar from 'components/Sidebar/Sidebar'
import {
  ChatMode, MessageTypingAnimation, Stop,
} from 'reducers/chat/types'
import UploadDocument from 'components/UploadDocument/UploadDocument'
import Banner from 'components/Banner/Banner'
import DocumentList from 'components/DocumentList/DocumentList'
import terms from 'common/terms'
import { getFeedbackCriteria, getFeedbackQuestions, getUserFeedbacks } from 'reducers/feedback/feedback.thunk'
import FeedbackRating from 'components/FeedbackRating/FeedbackRating'
import FeedbackNotification from 'components/FeedbackNotification/FeedbackNotification'
import { getAllDocuments } from 'reducers/documents/document.thunk'
import { DocumentTypes } from 'reducers/documents/types'
import { hasPendingDoc } from 'utils/functions'
import { resetDocumentList } from 'reducers/documents/document.reducers'
import './chat.scss'

function Chat(): ReactElement {
  const dispatch = useAppDispatch()
  const {
    chat, isWaiting, chatList, currentMessage,
  } = useAppSelector(state => state.chat)
  const { userFeedback, questions } = useAppSelector(state => state.feedback)
  const { documentListUser } = useAppSelector(state => state.document)
  const [chatMode, setChatMode] = useState<ChatMode>(ChatMode.digidoc)
  const [docMode, setDocMode] = useState<DocumentTypes>(DocumentTypes.digidoc)
  const [openUploadDocument, setOpenUploadDocument] = useState<boolean>(false)
  const [openDocuments, setOpenDocuments] = useState<boolean>(false)
  const [message, setMessage] = useState('')
  const [feedbackRatingOpen, setFeedbackRatingOpen] = useState<boolean>(false)
  const [isStopped, setIsStopped] = useState<Stop | null>(null)
  const [typingAnimation, setTypingAnimation] = useState<MessageTypingAnimation>({
    id: null,
    isTypingAnimation: false,
  })

  useEffect(() => {
    dispatch(getFeedbackQuestions())
    dispatch(getFeedbackCriteria())
    dispatch(getUserFeedbacks())
    dispatch(getChatList())
    dispatch(getTags())
  }, [])

  const handleSetChatMode = (mode: ChatMode) => {
    setChatMode(mode)
    if (mode === ChatMode.myDocuments) {
      dispatch(getAllDocuments({
        page: 1,
        source: DocumentTypes.myDocuments,
      }))
    }
  }

  useEffect(() => {
    if (chatList.length > 19 && userFeedback?.ratingFeedback === null) {
      setFeedbackRatingOpen(true)
    }
  }, [userFeedback, chatList])

  const handleSend = () => {
    setIsStopped(null)
    if (message) {
      if (chat) {
        dispatch(sendMessage({
          id: chat.id,
          message: {
            mode: chatMode,
            content: message,
          },
        }))
      } else {
        dispatch(createChat()).then(res => {
          dispatch(sendMessage({
            id: res.payload.id,
            message: {
              mode: chatMode,
              content: message,
            },
          }))
        })
      }
    }
    setMessage('')
  }

  const getQuestion = () => questions[0]?.content

  const handleOpenDocuments = () => {
    setOpenDocuments(true)
    dispatch(resetDocumentList())

    const mode = chatMode === ChatMode.myDocuments ? DocumentTypes.myDocuments : DocumentTypes.digidoc
    setDocMode(mode)
    dispatch(getAllDocuments({
      page: 1,
      source: mode,
    }))
  }

  const handleClickStopAnimation = () => {
    controller.abort()
    if (currentMessage) {
      setIsStopped({
        isStopped: true,
        id: currentMessage.id,
      })
    }
  }

  return (
    <>
      <Banner />
      <Sidebar
        setOpenUploadDocument={() => setOpenUploadDocument(true)}
        setOpenDocuments={handleOpenDocuments}
        setIsStopped={setIsStopped}
      />
      <div className="chat">
        <MessageList
          messages={chat?.messages || []}
          isStopped={isStopped}
          typingAnimation={typingAnimation}
          setTypingAnimation={setTypingAnimation}
        />
        <div>
          {isWaiting && (
            <div>
              <Waiting
                waitingMessage={terms.Chat.waitingMessage}
              />
            </div>

          )}
          <SendInput
            message={message}
            setMessage={setMessage}
            handleSend={handleSend}
            placeholder={terms.Chat.Input.placeholder}
            setChatMode={handleSetChatMode}
            chatMode={chatMode}
            handleClickStopAnimation={handleClickStopAnimation}
            typingAnimation={typingAnimation}
            isStopped={isStopped}
          />
        </div>

        <div className="feedback-status">
          {
            hasPendingDoc(documentListUser) && (
            <span>
              {hasPendingDoc(documentListUser)}
            </span>
            )
          }
        </div>
      </div>
      <UploadDocument
        open={openUploadDocument}
        handleClose={() => setOpenUploadDocument(false)}
      />
      <DocumentList
        open={openDocuments}
        handleClose={() => setOpenDocuments(false)}
        setOpenUploadDocument={() => setOpenUploadDocument(true)}
        docMode={docMode}
        setDocMode={setDocMode}
      />
      <FeedbackRating
        open={feedbackRatingOpen}
        handleClose={() => setFeedbackRatingOpen(false)}
      />
      {
        userFeedback && (
          <FeedbackNotification
            open={!userFeedback?.hasAnsweredLatestQuestion || false}
            question={getQuestion() || ''}
          />
        )
      }
    </>
  )
}

export default Chat
