import { createSlice } from '@reduxjs/toolkit'
import {
  deleteDocument, getAllDocuments, getDocumentById, getUserDocuments, uploadDocument,
} from './document.thunk'
import { DocumentState } from './types'

const initialDocumentList = {
  count: 0,
  next: '',
  previous: '',
  results: [],
}

const initialState: DocumentState = {
  document: null,
  documentList: initialDocumentList,
  documentListUser: [],
  isUploading: false,
  isLoading: false,
}

export const document = createSlice({
  name: 'document',
  initialState,
  reducers: {
    resetDocumentList: state => {
      state.documentList = initialDocumentList
    },
  },
  extraReducers: builder => {
    builder.addCase(getAllDocuments.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getAllDocuments.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(getAllDocuments.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.documentList = payload
    })
    builder.addCase(getUserDocuments.fulfilled, (state, { payload }) => {
      state.documentListUser = payload.results
    })
    builder.addCase(getDocumentById.fulfilled, (state, { payload }) => {
      state.document = payload
    })
    builder.addCase(deleteDocument.fulfilled, (state, { meta }) => {
      state.documentList.results = state.documentList.results.filter(i => i.id !== meta.arg)
    })
    builder.addCase(uploadDocument.pending, state => {
      state.isUploading = true
    })
    builder.addCase(uploadDocument.fulfilled, (state, { payload }) => {
      state.isUploading = false
      state.documentList = {
        ...state.documentList,
        results: [...state.documentList.results, payload],
      }
    })
    builder.addCase(uploadDocument.rejected, state => {
      state.isUploading = false
    })
  },
})

export const { resetDocumentList } = document.actions

export default document.reducer
