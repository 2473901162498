import { deleteRequest, get, post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ParamsGetAllDocuments } from './types'

const getDocumentById = createAsyncThunk(
  'document/getDocumentById',
  async (id: number, ThunkAPI) => {
    try {
      const response = await get(`/pythie/documents/${id}/`)
      return response
    } catch (error) {
      return ThunkAPI.rejectWithValue(error)
    }
  },
)

const getAllDocuments = createAsyncThunk(
  'document/getAllDocuments',
  async (params: ParamsGetAllDocuments, ThunkAPI) => {
    try {
      const response = await get('/pythie/documents/', {
        source: params.source,
        search: params?.search || '',
        page: params.page,
      })
      return response
    } catch (error) {
      return ThunkAPI.rejectWithValue(error)
    }
  },
)

const getUserDocuments = createAsyncThunk(
  'document/getUserDocuments',
  async (_, ThunkAPI) => {
    try {
      const response = await get('/pythie/documents/', {
        source: 'user',
        search: '',
        page: 1,
      })
      return response
    } catch (error) {
      return ThunkAPI.rejectWithValue(error)
    }
  },
)

const uploadDocument = createAsyncThunk(
  'document/uploadDocument',
  async (document: File, ThunkAPI) => {
    try {
      const formData = new FormData()
      formData.append('file', document)
      formData.append('source', 'user')
      const response = await post('/pythie/documents/', formData)
      return response
    } catch (error) {
      return ThunkAPI.rejectWithValue(error)
    }
  },
)

const deleteDocument = createAsyncThunk(
  'document/deleteDocument',
  async (id: number, ThunkAPI) => {
    try {
      const response = await deleteRequest(`/pythie/documents/${id}/`)
      return response
    } catch (error) {
      return ThunkAPI.rejectWithValue(error)
    }
  },
)

export {
  getDocumentById,
  uploadDocument,
  getAllDocuments,
  deleteDocument,
  getUserDocuments,
}
