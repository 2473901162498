import terms from 'common/terms'
import { ReactElement } from 'react'
import { ChatMode, MessageTypingAnimation, Stop } from 'reducers/chat/types'
import CheckIcon from '@mui/icons-material/Check'
import { TooltipLight } from 'components/Theme/TooltipLight'
import StopCircleIcon from '@mui/icons-material/StopCircle'

import './SwitchMode.scss'
import { useAppSelector } from 'hooks'

interface Props {
  setChatMode: (mode: ChatMode) => void
  chatMode: ChatMode
  handleClickStopAnimation: () => void
  typingAnimation: MessageTypingAnimation
  isStopped: Stop | null
}

const SwitchMode = ({
  setChatMode, chatMode, handleClickStopAnimation, typingAnimation, isStopped,
}: Props): ReactElement => {
  const { isWaiting } = useAppSelector(state => state.chat)
  const handleClick = () => {
    if (chatMode !== ChatMode.chat) {
      setChatMode(ChatMode.chat)
    } else {
      setChatMode(ChatMode.digidoc)
    }
  }
  return (
    <div className="switch-mode">
      <div className="wrapper">
        <div className={`select ${(chatMode !== ChatMode.chat) ? 'check' : ''}`}>
          <div className="settings">
            <button type="button" onClick={handleClick}>
              <span className="icon">
                {(chatMode !== ChatMode.chat) && <CheckIcon />}
              </span>
              <span>
                {terms.Chat.SwitchMode.info}
              </span>
            </button>
            {
              ((isWaiting || typingAnimation.isTypingAnimation) && !isStopped) && (
                <div className="stop-animation">
                  <TooltipLight
                    title={terms.Chat.Message.stopButton}
                    placement="top"
                  >
                    <StopCircleIcon
                      className="icon"
                      onClick={handleClickStopAnimation}
                    />
                  </TooltipLight>
                </div>
              )
            }
          </div>

        </div>
        {
          (chatMode !== ChatMode.chat) && (
            <div className="inputs">
              <span className="label">
                {terms.Chat.SwitchMode.label}
              </span>
              <button
                className={`button ${chatMode === ChatMode.digidoc ? 'active' : ''}`}
                type="button"
                value="digidoc"
                onClick={() => setChatMode(chatMode === ChatMode.digidoc ? ChatMode.chat : ChatMode.digidoc)}
              >
                {terms.Chat.SwitchMode.button.digidoc}
              </button>
              <span className="label">
                {terms.Chat.SwitchMode.or}
              </span>
              <div className="block">
                <button
                  className={`button ${chatMode === ChatMode.myDocuments ? 'active' : ''}`}
                  type="button"
                  onClick={() => setChatMode(chatMode === ChatMode.myDocuments ? ChatMode.chat : ChatMode.myDocuments)}
                >
                  {terms.Chat.SwitchMode.button.myDocuments}
                </button>
              </div>
            </div>
          )
        }

      </div>
    </div>
  )
}

export default SwitchMode
