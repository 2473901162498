import { TextField } from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { Tags } from 'reducers/chat/types'
import CheckIcon from '@mui/icons-material/Check'
import { v4 as uuidV4 } from 'uuid'

interface Props {
  tags: Tags[]
  tagsSelected: Tags[]
  handleClickTags: (tag: Tags) => void
  comment: string
  setComment: (value: string) => void
  suggestion: string | null
  setSuggestion: (value: string) => void
  handleClickSend: () => void
}

const FeedbackForm = ({
  tags,
  tagsSelected,
  handleClickTags,
  comment,
  setComment,
  suggestion,
  setSuggestion,
  handleClickSend,
}: Props): ReactElement => (
  <div className="feedback-input">
    <div className="separation" />
    <p>
      {terms.Chat.Feedback.question}
    </p>
    <div className="tags">
      {
          tags.map(tag => (
            <div
              key={uuidV4()}
              className={`select ${(tagsSelected && (tagsSelected.some(i => i.name === tag.name))) ? 'check' : ''}`}
            >
              <button
                type="button"
                onClick={() => handleClickTags(tag)}
              >
                <span className="icon">
                  {tagsSelected && (tagsSelected.some(i => i.name === tag.name)) && <CheckIcon />}
                </span>
                <span>
                  {tag.name}
                </span>
              </button>
            </div>
          ))
        }

    </div>
    <TextField
      placeholder={terms.Chat.Feedback.Inputs.comment}
      className="textarea"
      size="small"
      value={comment}
      onChange={e => setComment(e.target.value)}
      id="feedback-chat-comment"
      multiline
      rows={3}
    />
    <TextField
      placeholder={terms.Chat.Feedback.Inputs.response}
      className="textarea"
      size="small"
      value={suggestion}
      onChange={e => setSuggestion(e.target.value)}
      id="feedback-chat-response"
      multiline
      rows={3}
    />
    <div className="button">
      <button
        disabled={!(comment || suggestion || tagsSelected.length)}
        type="button"
        onClick={handleClickSend}
      >
        {terms.Chat.Feedback.Inputs.send}
      </button>
    </div>
  </div>
)

export default FeedbackForm
