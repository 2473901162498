import CustomButton, { ButtonVariant } from 'components/CustomButton/CustomButton'
import { ReactElement } from 'react'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import terms from 'common/terms'

interface Props {
  setOpenUploadDocument: () => void;
  setOpenDocuments: () => void;
}

function DocumentButtons({ setOpenUploadDocument, setOpenDocuments }: Props): ReactElement {
  return (
    <div className="document-buttons">
      <CustomButton
        title={terms.Sidebar.Buttons.uploadDocument}
        className="import-button"
        variant={ButtonVariant.secondaryOutlined}
        handleClick={setOpenUploadDocument}
        icon={<CloudUploadOutlinedIcon />}
      />
      <CustomButton
        title={terms.Sidebar.Buttons.documents}
        className="document-button"
        variant={ButtonVariant.secondaryText}
        handleClick={setOpenDocuments}
      />
    </div>
  )
}

export default DocumentButtons
