import { get, post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ParamsSendFeedbackApp, ParamsSendFeedbackrating } from './types'

const sendFeedbackApp = createAsyncThunk(
  'feedback/sendFeedbackApp',
  async (data: ParamsSendFeedbackApp, thunkAPI) => {
    const formData = new FormData()
    formData.append('type', data.type)
    formData.append('description', data.description)
    if (data.image) {
      formData.append('image', data.image)
    }
    try {
      const response = await post('/pythie/problem-feedbacks/', formData)
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const getUserFeedbacks = createAsyncThunk(
  'feedback/getUserFeedbacks',
  async (_, thunkAPI) => {
    try {
      const response = await get('/pythie/user-feedback/')
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const sendFeedbackRating = createAsyncThunk(
  'feedback/sendFeedbackRating',
  async (data: ParamsSendFeedbackrating, thunkAPI) => {
    try {
      const response = await post('/pythie/rating-feedback/', data)
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const getFeedbackCriteria = createAsyncThunk(
  'feedback/getFeedbackCriteria',
  async (_, thunkAPI) => {
    try {
      const response = await get('/pythie/criteria/')
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const getFeedbackQuestions = createAsyncThunk(
  'feedback/getFeedbackQuestions',
  async (_, thunkAPI) => {
    try {
      const response = await get('/pythie/questions/')
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const sendFeedbackNotification = createAsyncThunk(
  'feedback/sendFeedbackNotification',
  async (data: {question: string, answer: string}, thunkAPI) => {
    try {
      const response = await post('/pythie/question-feedbacks/', data)
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export {
  sendFeedbackApp,
  getUserFeedbacks,
  sendFeedbackRating,
  getFeedbackCriteria,
  getFeedbackQuestions,
  sendFeedbackNotification,
}
