import terms from 'common/terms'
import Dropzone, { FileRejection } from 'react-dropzone'
import ModalWrapper from 'components/ModalWrapper/ModalWrapper'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import CustomButton, { ButtonVariant } from 'components/CustomButton/CustomButton'
import Waiting from 'components/Waiting/Waiting'
import { useAppDispatch, useAppSelector } from 'hooks'
import {
  getDocumentById, getUserDocuments, uploadDocument,
} from 'reducers/documents/document.thunk'
import { useRef, useState } from 'react'
import { setSnackbar } from 'reducers/feedback/feedback.reducers'
import { SnackbarSeverity } from 'reducers/feedback/types'
import { DocumentItem, DocumentTraitementStatus } from 'reducers/documents/types'
import { v4 as uuidv4 } from 'uuid'

import './UploadDocument.scss'

interface Props {
  open: boolean
  handleClose: () => void
}

const UploadDocument = ({ open, handleClose }: Props) => {
  const dispatch = useAppDispatch()
  const fetchStatus = useRef<{id: number, timer: NodeJS.Timer | NodeJS.Timeout}[]>([])
  const { isUploading } = useAppSelector(state => state.document)
  const [fileSelected, setFileSelected] = useState<File[]>([])
  const DOCUMENT_FILE_SIZE_LIMIT = 20 * 1024 * 1024 // 20MiB

  const acceptedTypes = {
    'application/pdf': ['.pdf'],
  }

  const handleClickClose = () => {
    setFileSelected([])
    handleClose()
  }

  const clearFetchInterval = (id: number) => {
    fetchStatus.current.map(item => {
      if (item.id === id) {
        clearInterval(item.timer as NodeJS.Timeout)
      }
      return item
    })
    fetchStatus.current = fetchStatus.current.filter(item => item.id !== id)
  }

  const checkStatus = (documentId: number, document: DocumentItem) => {
    if (!fetchStatus.current.find(item => item.id === documentId)
       && [DocumentTraitementStatus.PENDING, DocumentTraitementStatus.STARTED].includes(
        document?.processingStatus as DocumentTraitementStatus,
       )) {
      fetchStatus.current = [
        ...fetchStatus.current,
        {
          id: documentId,
          timer: setInterval(
            () => {
              dispatch(getDocumentById(documentId)).then(response => checkStatus(documentId, response.payload))
            },
            10000,
          ),
        },

      ]
      dispatch(getUserDocuments())
      return
    }
    if (document?.processingStatus === DocumentTraitementStatus.SUCCESS) {
      clearFetchInterval(documentId)
      dispatch(setSnackbar({
        message: terms.Document.Upload.traitement.success,
        severity: SnackbarSeverity.SUCCESS,
      }))
      dispatch(getUserDocuments())
    }
    if (document?.processingStatus === DocumentTraitementStatus.FAILURE) {
      clearFetchInterval(documentId)
      dispatch(setSnackbar({
        message: terms.Document.Upload.traitement.error,
        severity: SnackbarSeverity.ERROR,
      }))
      dispatch(getUserDocuments())
    }
  }

  const handleSaveDocument = () => {
    if (fileSelected.length) {
      fileSelected.forEach(file => {
        dispatch(uploadDocument(file)).then(res => {
          if (!res.type.endsWith('rejected')) {
            dispatch(getDocumentById(res.payload.id)).then(response => {
              checkStatus(res.payload.id, response.payload)
            })
          }
        })
      })
      setFileSelected([])
    }
  }

  const onDropRejected = (reject: FileRejection[]) => {
    switch (reject[0].errors[0].code) {
      case 'file-invalid-type':
        dispatch(setSnackbar({
          severity: SnackbarSeverity.ERROR,
          message: terms.Document.Upload.error.invalidType,
        }))
        break
      case 'too-many-files':
        dispatch(setSnackbar({
          severity: SnackbarSeverity.ERROR,
          message: terms.Document.Upload.error.tooManyFiles,
        }))
        break
      case 'file-too-large':
        dispatch(setSnackbar({
          severity: SnackbarSeverity.ERROR,
          message: terms.Document.Upload.error.fileTooLarge,
        }))
        break
      default:
        dispatch(setSnackbar({
          severity: SnackbarSeverity.ERROR,
          message: 'Une erreur est survenue',
        }))
    }
  }

  const dropzoneClassName = `dropzone ${isUploading ? 'pending' : ''} ${(fileSelected) ? 'has-files' : ''}`
  return (
    <ModalWrapper
      title={terms.Document.Upload.title}
      open={open}
      handleClose={handleClickClose}
    >
      <section className="upload">
        {isUploading && (
          <div className="feedback">
            <span>{terms.Document.Upload.uploadPending.title}</span>
            <span>{terms.Document.Upload.uploadPending.info}</span>
            <span>{terms.Document.Upload.uploadPending.message}</span>
            <Waiting />
          </div>
        )}
        <Dropzone
          maxFiles={10}
          maxSize={DOCUMENT_FILE_SIZE_LIMIT} // 20MiB
          disabled={isUploading}
          onDropRejected={reject => onDropRejected(reject)}
          onDropAccepted={file => setFileSelected(file)}
          accept={acceptedTypes}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: dropzoneClassName })}>
              <input {...getInputProps()} />
              <FileDownloadOutlinedIcon className="icon" />
              <p>{terms.Document.Upload.dragFile}</p>
              <p>{terms.Document.Upload.selectFile}</p>
            </div>
          )}
        </Dropzone>
        <div className="file">
          { Boolean(fileSelected.length) && (
            <div className="wrap">
              {
                fileSelected.map((file: File) => (
                  <div key={uuidv4()} className="item">
                    <span>
                      {file.name}
                    </span>
                    <button
                      disabled={isUploading}
                      type="button"
                      onClick={() => setFileSelected(fileSelected.filter(f => f !== file))}
                    >
                      <ClearOutlinedIcon className="delete" />
                    </button>
                  </div>
                ))
              }
            </div>
          )}
        </div>
        <div className="buttons">
          <div>
            <CustomButton
              title={terms.Common.close}
              variant={ButtonVariant.blacktext}
              handleClick={handleClickClose}
            />
          </div>
          <div>
            <CustomButton
              disabled={!fileSelected || isUploading}
              title={terms.Common.save}
              variant={ButtonVariant.secondary}
              handleClick={handleSaveDocument}
            />
          </div>
        </div>
      </section>
    </ModalWrapper>
  )
}

export default UploadDocument
