import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getHasAcceptedAgreements } from './agreements/agreement.thunk'

interface AppState {
  error: null | undefined,
  fullscreen: boolean,
  isAppLoading?: boolean,
}

const initialState: AppState = {
  error: null,
  fullscreen: false,
  isAppLoading: true,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleFullscreen: state => {
      state.fullscreen = !state.fullscreen
    },
    getError: (state, action: PayloadAction<null>) => {
      state.error = action.payload
    },
    setIsAppLoading: (state, action: PayloadAction<boolean>) => {
      state.isAppLoading = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getHasAcceptedAgreements.pending, state => {
      state.isAppLoading = true
    })
    builder.addCase(getHasAcceptedAgreements.fulfilled, state => {
      state.isAppLoading = false
    })
    builder.addCase(getHasAcceptedAgreements.rejected, state => {
      state.isAppLoading = false
    })
  },
})

export const {
  toggleFullscreen,
  getError,
} = appSlice.actions

export default appSlice.reducer
