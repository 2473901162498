import Pagination from '@mui/material/Pagination'
import PaginationItem from '@mui/material/PaginationItem'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { ReactElement } from 'react'

interface Props {
  count: number
  handleChange: (value: number) => void
  page: number
}

const CustomPagination = ({ count, page, handleChange }: Props): ReactElement => (
  <div>
    <Pagination
      className="pagination"
      count={count}
      color="secondary"
      page={page}
      onChange={(_, value) => handleChange(value)}
      renderItem={item => (
        <PaginationItem
          slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
          {...item}
        />
      )}
    />
  </div>
)

export default CustomPagination
