import {
  Backdrop,
  SelectChangeEvent,
} from '@mui/material'
import terms from 'common/terms'
import CustomButton, { ButtonVariant } from 'components/CustomButton/CustomButton'
import ModalWrapper from 'components/ModalWrapper/ModalWrapper'
import { ReactElement, useState } from 'react'
import { FeedbackAppEnum } from 'reducers/feedback/types'
import { useAppDispatch, useAppSelector } from 'hooks'
import { sendFeedbackApp } from 'reducers/feedback/feedback.thunk'
import CircularProgress from '@mui/material/CircularProgress'
import FeedbackAppInputs from './FeedbackAppInputs/FeedbackAppInputs'

import './FeedbackApp.scss'

interface Props {
  open: boolean
  handleClose: () => void
}

const FeedbackApp = ({ open, handleClose }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const { isSending } = useAppSelector(state => state.feedback)
  const [descriptionInput, setDescriptionInput] = useState<string>('')
  const [imageInput, setImageInput] = useState<File | null>(null)
  const [typeInput, setTypeInput] = useState<FeedbackAppEnum | string>(terms.Feedback.App.placeholder.type)

  const handleChangeSelect = (event: SelectChangeEvent<unknown>) => {
    setTypeInput(event.target.value as FeedbackAppEnum)
  }

  const handleClickClose = () => {
    setDescriptionInput('')
    setImageInput(null)
    setTypeInput(terms.Feedback.App.placeholder.type)
    handleClose()
  }

  const handleClickSend = () => {
    dispatch(sendFeedbackApp({
      description: descriptionInput,
      image: imageInput,
      type: typeInput as FeedbackAppEnum,
    })).then(() => {
      handleClickClose()
      setDescriptionInput('')
      setImageInput(null)
      setTypeInput(terms.Feedback.App.placeholder.type)
    })
  }

  return (
    <ModalWrapper
      title={terms.Feedback.App.title}
      open={open}
      handleClose={handleClickClose}
      className={isSending ? 'active' : ''}
    >
      <div className={`feedback-app ${isSending ? 'active' : ''}`}>
        <FeedbackAppInputs
          typeInput={typeInput}
          handleChangeSelect={handleChangeSelect}
          descriptionInput={descriptionInput}
          setDescriptionInput={setDescriptionInput}
          imageInput={imageInput}
          setImageInput={setImageInput}
        />
        <div className="buttons">
          <div>
            <CustomButton
              title={terms.Common.close}
              variant={ButtonVariant.blacktext}
              handleClick={handleClickClose}
            />
          </div>
          <div>
            <CustomButton
              disabled={typeInput === terms.Feedback.App.placeholder.type || descriptionInput === ''}
              title={terms.Common.send}
              variant={ButtonVariant.secondary}
              handleClick={handleClickSend}
            />
          </div>

        </div>
        <Backdrop
          className="backdrop"
          open={isSending}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </ModalWrapper>
  )
}

export default FeedbackApp
