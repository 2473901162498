import { createSlice } from '@reduxjs/toolkit'
import {
  createChat,
  deleteChat,
  getChatById, getChatList, getTags, sendFeedback, sendMessage, updateFeedback, updateMessage,
} from './chat.thunk'
import { ChatState } from './types'

const initialState: ChatState = {
  chat: null,
  chatList: [],
  isWaiting: false,
  isGettingHistory: false,
  currentMessage: null,
  tags: [],
}

export const chat = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addMessage: (state, { payload }) => {
      state.chat?.messages?.push(payload)
    },
    removeChat: state => {
      state.chat = null
    },
    removeMessage: (state, { payload }) => {
      if (!state.chat) return
      state.chat.messages = state.chat.messages.filter(item => item.exchangeId !== payload)
    },
    setIsGettingHistory: (state, { payload }) => {
      state.isGettingHistory = payload
    },
    setCurrentMessage: (state, { payload }) => {
      state.currentMessage = payload
    },
  },
  extraReducers(builder) {
    builder.addCase(getChatList.fulfilled, (state, { payload }) => {
      state.chatList = payload
    })
    builder.addCase(getChatById.fulfilled, (state, { payload }) => {
      state.chat = payload
    })
    builder.addCase(sendMessage.pending, state => {
      state.isWaiting = true
      state.isGettingHistory = false
    })
    builder.addCase(sendMessage.fulfilled, state => {
      state.isWaiting = false
    })
    builder.addCase(sendMessage.rejected, state => {
      state.isWaiting = false
    })
    builder.addCase(updateMessage.fulfilled, (state, { payload }) => {
      if (!state.chat) return
      state.chat.messages = state.chat.messages.map(item => {
        if (item.id === payload.id) {
          return payload
        }
        return item
      })
    })
    builder.addCase(deleteChat.fulfilled, (state, arg) => {
      if (state.chat?.id === arg.meta.arg) state.chat = null
      state.chatList = state.chatList.filter(item => item.id !== arg.meta.arg)
    })
    builder.addCase(createChat.fulfilled, (state, { payload }) => {
      state.chat = payload
    })
    builder.addCase(getTags.fulfilled, (state, { payload }) => {
      state.tags = payload
    })
    builder.addCase(sendFeedback.fulfilled, (state, { payload }) => {
      if (!state.chat) return
      state.chat.messages = state.chat.messages.map(item => {
        if (item.id === payload.messageId) {
          return {
            ...item,
            feedback: payload,
          }
        }
        return item
      })
    })
    builder.addCase(updateFeedback.fulfilled, (state, { payload }) => {
      if (!state.chat) return
      state.chat.messages = state.chat.messages.map(item => {
        if (item.id === payload.messageId) {
          return {
            ...item,
            feedback: payload,
          }
        }
        return item
      })
    })
  },
})

export const {
  addMessage,
  removeChat,
  setIsGettingHistory,
  setCurrentMessage,
  removeMessage,
} = chat.actions

export default chat.reducer
