import { Box, Modal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ReactElement } from 'react'
import './ModalWrapper.scss'

interface Props {
  open: boolean
  handleClose?: () => void
  children: ReactElement | ReactElement[]
  title?: string
  className?: string
  variant?: 'primary' | 'secondary'
  closable?: boolean
}

const ModalWrapper = ({
  open, handleClose, children, className, title, variant = 'primary', closable,
}: Props): ReactElement => (
  <Modal open={open} onClose={handleClose} className="modal-wrapper">
    <Box className={`custom-modal ${className}`}>
      <div className={`header ${variant}`}>
        {
          title && <h2>{title}</h2>
        }
        {
          closable && (
            <button type="button" onClick={handleClose} tabIndex={0}>
              <CloseIcon />
            </button>
          )
        }
      </div>
      <div className="content">
        {children}
      </div>
    </Box>
  </Modal>
)

ModalWrapper.defaultProps = {
  className: undefined,
  variant: '',
  closable: true,
  handleClose: () => null,
  title: '',
}

export default ModalWrapper
