import { UserAccount } from '@osrdata/app_core/dist/auth/redux/actions'
import terms from 'common/terms'
import { Message, MessageTypingAnimation } from 'reducers/chat/types'
import { DocumentItem, DocumentTraitementStatus } from 'reducers/documents/types'
import { setSnackbar } from 'reducers/feedback/feedback.reducers'
import { SnackbarSeverity } from 'reducers/feedback/types'
import { store } from 'reducers/store'

/**
 * @param user: UserAccount
 * @returns { string }
 * @description
 * This function is used to get the user initials
*/
type GetUserInitials = (user: UserAccount) => string
const getUserInitials: GetUserInitials = user => `${user.firstName[0]}${user.lastName[0]}`

/**
 * @param maxWidth: number
 * @param messageSource: string
 * @param setText: (text: string) => void
 * @param isActive: boolean
 * @param setTypingAnimation: (typingAnimation: MessageTypingAnimation) => void
 * @param messageId: number
 * @returns { () => void }
 * @description
 * This function is used to play the typing animation of the chat
*/
type PlayTypingAnimation = (
  maxWidth: number,
  messageSource: string,
  setText: (text: string) => void,
  isActive: boolean,
  setTypingAnimation: (typingAnimation: MessageTypingAnimation) => void,
  messageId: number,
)=> () => void
const playTypingAnimation: PlayTypingAnimation = (
  maxWidth,
  messageSource,
  setText,
  isActive,
  setTypingAnimation,
  messageId,
) => {
  if (isActive) {
    let currentIndex = 0
    let currentText = ''
    const interval = setInterval(() => {
      if (currentIndex < messageSource.length) {
        currentText += messageSource[currentIndex]
        currentIndex += 1
        setText(currentText)
        if (messageSource.length > maxWidth) {
          const lastText = (prevText: string) => `${prevText}\n`
          setText(lastText(currentText))
        }
        setTypingAnimation({
          id: messageId,
          isTypingAnimation: true,
        })
      } else {
        clearInterval(interval)
        setTypingAnimation({
          id: messageId,
          isTypingAnimation: false,
        })
      }
    }, 12)

    setTypingAnimation({
      id: messageId,
      isTypingAnimation: false,
    })

    return () => clearInterval(interval)
  }
  setTypingAnimation({
    id: messageId,
    isTypingAnimation: false,
  })
  return () => setText(messageSource)
}

/**
 * @param dispatchAddMessage: (arg0: Message) => void
 * @param dispatchSncakbarError: () => void
 * @returns { onDownloadProgressHandler: (progressEvent: { target: { response: string } }) => void }
 * @description
 * This function is used to handle the download progress of the SSE stream
 * It parses the response and dispatches the message to the store
*/
const getOnDownloadProgressHandler = (
  dispatchAddMessage: (arg0: Message) => void,
  dispatchSncakbarError: () => void,
) => {
  let prevTarget = ''
  async function onDownloadProgressHandler(progressEvent: { target: { response: string } }) {
    const currentTarget = progressEvent.target.response
    const diffTarget = currentTarget.substring(prevTarget.length)
    const lines = diffTarget.split('\n')
    const linesFiltered = lines.filter((line: string) => line !== '')
    prevTarget = currentTarget
    linesFiltered.forEach((line: string) => {
      try {
        const message: Message = JSON.parse(line)
        dispatchAddMessage(message)
      } catch (error) {
        dispatchSncakbarError()
      }
    })
  }
  return onDownloadProgressHandler
}

/**
 * @param chatMode: ChatMode
 * @param myDocuments: DocumentItem[]
 * @returns string | null
 * @description
 * This function is used to display a message when the user is in the myDocuments mode
 * and has documents in pending or started status
*/
type HasPendingDoc = (myDocuments: DocumentItem[]) => string | null
const hasPendingDoc: HasPendingDoc = myDocuments => {
  const nbDoc = myDocuments.filter(
    doc => [DocumentTraitementStatus.PENDING, DocumentTraitementStatus.STARTED].includes(
        doc.processingStatus as DocumentTraitementStatus,
    ),
  ).length
  if (nbDoc) {
    if (nbDoc > 1) {
      return terms.Chat.Feedback.myDocuments.alertMessage.severalDocuments(nbDoc)
    }
    return terms.Chat.Feedback.myDocuments.alertMessage.oneDocument
  }
  return null
}

/**
 * @param messages: Message[]
 * @param typingAnimation: MessageTypingAnimation
 * @param setMessages: (newMessages: Message[]) => void
 * @returns void
 * @description
 * This function is used to display messages in the chat
*/
type GetMessagesToDisplay = (
  messages: Message[],
  typingAnimation: MessageTypingAnimation,
  setMessages: (newMessages: Message[]) => void,
) => void
const getMessagesToDisplay: GetMessagesToDisplay = (messages, typingAnimation, setMessages) => {
  // If is typing animation is true and the last message is not the typing animation
  // remove the last message
  if (messages.findIndex(
    item => item.id === typingAnimation.id,
  ) !== messages.length - 1 && typingAnimation.isTypingAnimation) {
    const messageList = [...messages]
    // Message list less the last message
    messageList.pop()
    setMessages(messageList)
  // when the typing animation is finished, add new message
  } else {
    setMessages(messages)
  }
}

const handleCopyMessage = (text: string) => {
  navigator.clipboard.writeText(text)
    .then(() => {
      store.dispatch(setSnackbar({
        message: terms.Chat.Message.copySuccess,
        severity: SnackbarSeverity.SUCCESS,
      }))
    })
    .catch(() => {
      store.dispatch(setSnackbar({
        message: terms.Chat.Message.copyError,
        severity: SnackbarSeverity.ERROR,
      }))
    })
}

export {
  getUserInitials,
  playTypingAnimation,
  getOnDownloadProgressHandler,
  hasPendingDoc,
  getMessagesToDisplay,
  handleCopyMessage,
}
