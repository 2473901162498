// ENUMS

export enum SnackbarSeverity {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export enum FeedbackAppEnum {
  BUG = 'bug',
  ERGONOMY = 'ergonomie',
  OTHER = 'autre',
}

// TYPES

export type FeedbackState = {
  displaySnackbar: boolean,
  snackbarMessage: string,
  snackbarSeverity: SnackbarSeverity | undefined,
  isSending: boolean,
  userFeedback: UserFeedback | null,
  ratings: Rating[],
  questions: {content: string}[],
}

export type UserFeedback = {
  hasAnsweredLatestQuestion: boolean,
  questionFeedback: QuestionFeedback[],
  problemFeedbacks: ProblemFeedback[],
  ratingFeedback: RatingFeedback,
}

export type QuestionFeedback = {
  question: string,
  answer: string,
}

export type ProblemFeedback = {
  type: FeedbackAppEnum,
  description: string,
  image: string,
}

export type RatingFeedback = {
  comment: string,
  ratings: Rating[],
}

export type ParamsSendFeedbackrating = {
  comment: string,
  ratings: {
    stars: number,
    description: string,
  }[],
}

export type ParamsSendFeedbackApp = {
  type: FeedbackAppEnum,
  description: string,
  image: File | null,
}

export type Rating = {
  id: string,
  description: string,
  stars: number,
}
