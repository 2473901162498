import {
  MenuItem, FormControl, FormLabel, Select, SelectChangeEvent,
} from '@mui/material'
import { ReactElement } from 'react'
import { v4 as uuidV4 } from 'uuid'

import './CustomSelect.scss'

interface Props {
  inputValue: string;
  handleChange: (event: SelectChangeEvent<string>) => void;
  placeholder: string;
  label?: string;
  options: string[];
  size?: 'small' | 'medium';
  required?: boolean;
}

const CustomSelect = ({
  inputValue, handleChange, placeholder, label, options, size, required,
}: Props): ReactElement => (
  <FormControl fullWidth className="custom-select">
    {
        label && (
          <FormLabel required={required} className="label">
            {label}
          </FormLabel>
        )
    }
    <Select
      className="select"
      variant="outlined"
      size={size}
      value={inputValue}
      onChange={handleChange}
      renderValue={value => (
        <span className={value === placeholder ? 'placeholder' : 'select-input'}>{value}</span>)}
    >
      <MenuItem value={placeholder} disabled>
        <em>{placeholder}</em>
      </MenuItem>
      {
          options.map(option => (
            <MenuItem key={uuidV4()} value={option}>
              <span className="option">
                {option}
              </span>
            </MenuItem>
          ))
        }
    </Select>
  </FormControl>
)

CustomSelect.defaultProps = {
  label: undefined,
  size: 'small',
  required: false,
}

export default CustomSelect
