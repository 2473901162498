import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import terms from 'common/terms'
import { sendAgreement } from 'reducers/agreements/agreement.thunk'
import { deleteChat, sendFeedback, updateFeedback } from 'reducers/chat/chat.thunk'
import { deleteDocument, uploadDocument } from 'reducers/documents/document.thunk'
import { v4 as uuidV4 } from 'uuid'
import {
  getFeedbackCriteria,
  getFeedbackQuestions,
  getUserFeedbacks,
  sendFeedbackApp,
  sendFeedbackNotification,
  sendFeedbackRating,
} from './feedback.thunk'
import { FeedbackState, SnackbarSeverity } from './types'

const initialState: FeedbackState = {
  displaySnackbar: false,
  snackbarMessage: '',
  snackbarSeverity: undefined,
  isSending: false,
  userFeedback: null,
  questions: [],
  ratings: [],
}

export const feedback = createSlice({
  name: 'agreement',
  initialState,
  reducers: {
    setSnackbar: (state, action: PayloadAction<{ message: string, severity: SnackbarSeverity }>) => {
      state.displaySnackbar = true
      state.snackbarMessage = action.payload.message
      state.snackbarSeverity = action.payload.severity
    },
    hideSnackbar: state => {
      state.displaySnackbar = false
      state.snackbarMessage = ''
      state.snackbarSeverity = undefined
    },
  },
  extraReducers(builder) {
    builder.addCase(sendAgreement.fulfilled, state => {
      state.displaySnackbar = true
      state.snackbarMessage = terms.Licence.Snackbar.createSuccess
      state.snackbarSeverity = SnackbarSeverity.SUCCESS
    })
    builder.addCase(deleteChat.fulfilled, state => {
      state.displaySnackbar = true
      state.snackbarMessage = terms.Chat.Snackbar.deleteSuccess
      state.snackbarSeverity = SnackbarSeverity.SUCCESS
    })
    builder.addCase(uploadDocument.fulfilled, state => {
      state.displaySnackbar = true
      state.snackbarMessage = terms.Document.Snackbar.uploadSuccess
      state.snackbarSeverity = SnackbarSeverity.SUCCESS
    })
    builder.addCase(deleteDocument.fulfilled, state => {
      state.displaySnackbar = true
      state.snackbarMessage = terms.Document.Snackbar.deleteSuccess
      state.snackbarSeverity = SnackbarSeverity.SUCCESS
    })
    builder.addCase(sendFeedback.fulfilled, state => {
      state.displaySnackbar = true
      state.snackbarMessage = terms.Chat.Feedback.Snackbar.sendSuccess
      state.snackbarSeverity = SnackbarSeverity.SUCCESS
    })
    builder.addCase(updateFeedback.fulfilled, state => {
      state.displaySnackbar = true
      state.snackbarMessage = terms.Chat.Feedback.Snackbar.updateSuccess
      state.snackbarSeverity = SnackbarSeverity.SUCCESS
    })
    builder.addCase(sendFeedbackApp.pending, state => {
      state.isSending = true
    })
    builder.addCase(sendFeedbackApp.fulfilled, state => {
      state.isSending = false
      state.displaySnackbar = true
      state.snackbarMessage = terms.Feedback.App.snackbars.success
      state.snackbarSeverity = SnackbarSeverity.SUCCESS
    })
    builder.addCase(sendFeedbackApp.rejected, state => {
      state.isSending = false
      state.displaySnackbar = true
      state.snackbarMessage = terms.Feedback.App.snackbars.error
      state.snackbarSeverity = SnackbarSeverity.ERROR
    })
    builder.addCase(sendFeedbackRating.fulfilled, (state, { payload }) => {
      state.displaySnackbar = true
      state.snackbarMessage = terms.Feedback.Rating.snackbars.success
      state.snackbarSeverity = SnackbarSeverity.SUCCESS
      if (state.userFeedback) {
        state.userFeedback.ratingFeedback = payload
      }
    })
    builder.addCase(sendFeedbackRating.rejected, state => {
      state.displaySnackbar = true
      state.snackbarMessage = terms.Feedback.Rating.snackbars.error
      state.snackbarSeverity = SnackbarSeverity.ERROR
    })
    builder.addCase(getUserFeedbacks.fulfilled, (state, { payload }) => {
      state.userFeedback = payload
    })
    builder.addCase(getFeedbackCriteria.fulfilled, (state, { payload }) => {
      state.ratings = payload.map((item: {description: string}) => ({
        description: item.description,
        stars: 0,
        id: uuidV4(),
      }))
    })
    builder.addCase(getFeedbackQuestions.fulfilled, (state, { payload }) => {
      state.questions = payload
    })
    builder.addCase(sendFeedbackNotification.fulfilled, state => {
      state.displaySnackbar = true
      state.snackbarMessage = terms.Feedback.Notification.snackbars.success
      state.snackbarSeverity = SnackbarSeverity.SUCCESS
    })
    builder.addCase(sendFeedbackNotification.rejected, state => {
      state.displaySnackbar = true
      state.snackbarMessage = terms.Feedback.Notification.snackbars.error
      state.snackbarSeverity = SnackbarSeverity.ERROR
    })
  },
})

export const {
  setSnackbar,
  hideSnackbar,
} = feedback.actions

export default feedback.reducer
