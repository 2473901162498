import CGUWrapper from 'components/CGUWrapper/CGUWrapper'
import ReactMarkdown from 'react-markdown'
import { useAppDispatch, useAppSelector } from 'hooks'
import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import {
  getHasAcceptedAgreements,
  sendAgreement,
} from 'reducers/agreements/agreement.thunk'
import { Agreement } from 'reducers/agreements/types'

import './cguLicence.scss'

function CguLicence(): ReactElement {
  const dispatch = useAppDispatch()
  const cguRef = useRef<HTMLDivElement>(null)
  const { agreementList, acceptedAgreementsList, isLoadingSendAgreement } = useAppSelector(state => state.agreement)
  const [agreements, setAgreements] = useState<Agreement[]>([])

  useEffect(() => {
    if (acceptedAgreementsList.length > 0) {
      const acceptedAgreements = acceptedAgreementsList.flatMap(
        agreement => (agreement.accepted ? agreement.agreementId : []),
      )
      const filteredAgreements = agreementList.filter(agreement => !acceptedAgreements.includes(agreement.id))
      setAgreements(filteredAgreements)
    } else {
      setAgreements(agreementList)
    }

    if (
      acceptedAgreementsList.length
      && (acceptedAgreementsList.length === agreementList.length)
      && acceptedAgreementsList.every(agreement => agreement.accepted)) {
      dispatch(getHasAcceptedAgreements())
    }
  }, [acceptedAgreementsList, agreementList])

  const handleClick = () => {
    dispatch(sendAgreement({
      agreementId: agreements[0].id,
      accepted: true,
    }))
  }

  const scrollToTop = () => {
    if (cguRef.current) {
      cguRef.current.scrollTop = 0
    }
  }

  useEffect(() => {
    scrollToTop()
  }, [acceptedAgreementsList])

  return (
    <div className="cgu-licence">
      {
        agreements.length && (
          <CGUWrapper
            buttonLabel={agreements[0]?.buttonLabel}
            handleClick={handleClick}
            isLoading={isLoadingSendAgreement}
            cguRef={cguRef}
          >
            <div className="content">
              <ReactMarkdown>
                {agreements[0]?.content}
              </ReactMarkdown>
            </div>
          </CGUWrapper>
        )
      }
    </div>
  )
}

export default CguLicence
