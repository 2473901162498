import terms from 'common/terms'
import FeedbackApp from 'components/FeedbackApp/FeedbackApp'
import { ReactElement, useState } from 'react'

import './Banner.scss'

const Banner = (): ReactElement => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  return (
    <>
      <div className="banner">
        <div>
          {terms.Banner.label}
        </div>
        <button type="button" onClick={() => setOpenModal(true)}>
          {terms.Banner.button}
        </button>
      </div>
      <FeedbackApp
        open={openModal}
        handleClose={() => setOpenModal(false)}
      />
    </>
  )
}

export default Banner
