import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { RootState } from 'reducers/store'
import { NavTabElement } from 'utils/navTabElements'

import './TabElement.scss'

type Props = {
  element: NavTabElement;
}

export default function TabElement({ element }: Props): ReactElement {
  const {
    icon,
    title,
    path,
    role,
  } = element

  const location = useLocation()

  const isSelected = location.pathname.includes(path)

  const { appPermissions } = useSelector((state: RootState) => state.user)

  const isAllowed = role.length === 0
  || ((appPermissions as string[]).some(permission => role.includes(permission)))

  return isAllowed ? (
    <Link to={path}>
      <div className={`tab-element ${isSelected ? 'selected' : ''}`}>
        {icon && (
        <div className="mr-2">
          <img src={icon} alt="tab icon" />
        </div>
        )}
        <div className="title">
          {title}
        </div>
      </div>
    </Link>
  ) : <div />
}
