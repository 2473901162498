import { createSlice } from '@reduxjs/toolkit'
import {
  getAcceptedListAgreements, getAgreementlist, getHasAcceptedAgreements, sendAgreement,
} from './agreement.thunk'
import { AgreementsState } from './types'

const initialState: AgreementsState = {
  agreementList: [],
  hasAcceptedAgreements: false,
  acceptedAgreementsList: [],
  isLoadingSendAgreement: false,

}

export const agreement = createSlice({
  name: 'agreement',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAgreementlist.fulfilled, (state, { payload }) => {
      state.agreementList = payload
    })
    builder.addCase(getHasAcceptedAgreements.fulfilled, (state, { payload }) => {
      state.hasAcceptedAgreements = payload.accepted
    })
    builder.addCase(getAcceptedListAgreements.fulfilled, (state, { payload }) => {
      state.acceptedAgreementsList = payload
    })
    builder.addCase(sendAgreement.pending, state => {
      state.isLoadingSendAgreement = true
    })
    builder.addCase(sendAgreement.fulfilled, (state, action) => {
      state.isLoadingSendAgreement = false
      if (state.acceptedAgreementsList.length !== state.agreementList.length) {
        state.acceptedAgreementsList.push(action.payload)
        return
      }
      state.acceptedAgreementsList = state.acceptedAgreementsList.map(item => {
        if (item.agreementId === action.meta.arg.agreementId) {
          return action.payload
        }
        return item
      })
    })
    builder.addCase(sendAgreement.rejected, state => {
      state.isLoadingSendAgreement = false
    })
  },
})

export default agreement.reducer
