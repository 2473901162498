import { ReactElement, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks'
import { Tags, Thumbs, Message as MessageInterface } from 'reducers/chat/types'
import { sendFeedback, updateFeedback } from 'reducers/chat/chat.thunk'
import ThumbsInput from './ThumbsInput/ThumbsInput'
import FeedbackForm from './FeedbackForm/feedbackForm'

import './FeedbackChat.scss'

interface Props {
  message: MessageInterface
}

const FeedbackChat = ({ message }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const [thumb, setThumb] = useState<Thumbs | string>('')
  const [comment, setComment] = useState('')
  const [suggestion, setSuggestion] = useState<string | null>(null)
  const [tagsSelected, setTagsSelected] = useState<Tags[]>([])
  const { tags } = useAppSelector(state => state.chat)

  useEffect(() => {
    if (message.feedback) {
      setThumb(message.feedback.feedbackType || '')
      setComment(message.feedback.comment || '')
      setSuggestion(message.feedback.answerProposal || null)
      setTagsSelected(message.feedback.tags || [])
    }
  }, [message])

  const handleClickTags = (tag: Tags) => {
    if (tagsSelected.length && tagsSelected.some(i => i.name === tag.name)) {
      setTagsSelected(tagsSelected.filter(i => i.name !== tag.name))
    } else {
      setTagsSelected([...tagsSelected, tag])
    }
  }

  const handleClickThumb = (thumbValue: Thumbs) => {
    setThumb(thumbValue)
    if (message.feedback) {
      setComment('')
      setSuggestion(null)
      setTagsSelected([])
      dispatch(updateFeedback({
        messageId: message.id,
        feedbackType: thumbValue,
        comment: '',
        answerProposal: null,
        tags: [],
      }))
      return
    }
    dispatch(sendFeedback({
      messageId: message.id,
      feedbackType: thumbValue,
      tags: tagsSelected,
      comment,
      answerProposal: suggestion,
    })).catch(() => {
      if (message.feedback) {
        setThumb(message.feedback.feedbackType)
      } else {
        setThumb('')
      }
    })
  }

  const handleClickSend = () => {
    dispatch(updateFeedback({
      messageId: message.id,
      feedbackType: thumb,
      comment,
      answerProposal: suggestion,
      tags: tagsSelected,
    }))
  }
  return (
    <div className="feedback-chat">
      <ThumbsInput
        handleClick={handleClickThumb}
        thumb={thumb as Thumbs}
        message={message}
      />
      {
        (thumb === Thumbs.down) && (
          <FeedbackForm
            tags={tags}
            tagsSelected={tagsSelected}
            handleClickTags={handleClickTags}
            comment={comment}
            setComment={setComment}
            suggestion={suggestion}
            setSuggestion={setSuggestion}
            handleClickSend={handleClickSend}
          />
        )
      }
    </div>
  )
}

export default FeedbackChat
