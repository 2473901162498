// Enums

export enum DocumentTypes {
  digidoc = 'digidoc',
  myDocuments = 'user',
}

export enum DocumentTraitementStatus {
  PENDING = 'PENDING',
  STARTED = 'STARTED',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

// Types

export type DocumentState = {
  document: Document | null,
  documentList: DocumentList,
  documentListUser: [],
  isUploading: boolean,
  isLoading: boolean,
}

export type Document = {
  id: number,
  filename: string,
  source: string,
  url: string,
}

export type DocumentList = {
  count: number,
  next: string,
  previous: string,
  results: DocumentItem[],
}

export type DocumentItem = {
  id: number,
  filename: string,
  source: string,
  processingStatus: string,
}

export type ParamsGetAllDocuments = {
  source: DocumentTypes,
  search?: string,
  page: number,
}
