import terms from 'common/terms'
import { setSnackbar } from 'reducers/feedback/feedback.reducers'
import { SnackbarSeverity } from 'reducers/feedback/types'

type Action = {
  type: string,
  error?: {
    message: string,
  }
  payload: {
    code: number,
    data: string[],
    type: string,
    message?: string,
    response: {
      status: number,
      data?: {
        file?: string[],
      }
    },
  }
}

type Next =
  (arg0: {
    payload: {
      code: number
      data: string[]
      type: string
    }
    | {
      message: string
      severity: SnackbarSeverity
    };
    type: string
    error?: {
      message: string
    } | undefined
  }) => void

type ErrorInterceptorMiddleware = () => (next: Next) => (action: Action) => void
const errorInterceptorMiddleware: ErrorInterceptorMiddleware = () => (next: Next) => (action: Action) => {
  if (action.type.endsWith('/rejected')) {
    switch (action?.payload?.response?.status) {
      case 400:
        next(setSnackbar({
          message: action.payload.response.data?.file?.[0] || terms.Common.errorMessages.badRequest,
          severity: SnackbarSeverity.ERROR,
        }))
        break
      case 403:
        next(setSnackbar({
          message: terms.Common.errorMessages.forbidden,
          severity: SnackbarSeverity.ERROR,
        }))
        break
      case 404:
        next(setSnackbar({
          message: terms.Common.errorMessages.notFound,
          severity: SnackbarSeverity.ERROR,
        }))
        break
      case 500:
        next(setSnackbar({
          message: terms.Common.errorMessages.internalServerError,
          severity: SnackbarSeverity.ERROR,
        }))
        break
      case 502:
        next(setSnackbar({
          message: terms.Common.errorMessages.serverDown,
          severity: SnackbarSeverity.ERROR,
        }))
        break
      default:
        if (action.payload?.message !== 'canceled') {
          next(setSnackbar({
            message: terms.Common.errorMessages.unknownError,
            severity: SnackbarSeverity.ERROR,
          }))
        }
    }
  }

  return next(action)
}

export default errorInterceptorMiddleware
