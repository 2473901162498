import { ReactElement, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { PORTAL_URL } from 'config/config'
import terms from 'common/terms'
import logo from 'assets/logos/logo-DGEXSOL-full-white.svg'
import mistralAiIcon from 'assets/logos/mistral-ai.svg'
import AccountMenu from './AccountMenu'

import './TopBar.scss'

type Props = {
  appName: string;
  innerComponent?: ReactNode;
  hasAccess: boolean;
}

const defaultProps = {
  innerComponent: <div />,
}

export default function TopBar({ appName, innerComponent, hasAccess }: Props): ReactElement {
  const navigate = useNavigate()
  return (
    <div className="topbar">
      <header role="banner" className="sncf-banner">
        <div className="mastheader-logo">
          <a href={PORTAL_URL.url}><img alt={appName} src={logo} width="70" /></a>
        </div>
        <div
          className="app-name-container"
          aria-hidden
          role="button"
          onClick={() => navigate(terms.Path.home.path)}
        >
          <h1 className="app-name">{appName}</h1>
          <div className="tag">
            {terms.Common.topbarTag}
          </div>
          <div className="engine">
            <span className="content">
              {terms.Common.topbarEngineering.content}
            </span>
            <div className="logo">
              <img className="img" src={mistralAiIcon} alt="mistral-ai" />
              <div className="title">
                <span>
                  {terms.Common.topbarEngineering.title}
                </span>
                <span>
                  {terms.Common.topbarEngineering.subTitle}
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
      {hasAccess && innerComponent}
      <AccountMenu />
    </div>
  )
}

TopBar.defaultProps = defaultProps
