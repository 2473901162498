import Chat from 'components/Chat/Chat'
import { useAppSelector } from 'hooks'
import CguLicence from 'pages/CguLicence/CguLicence'
import './home.scss'

function Home() {
  const { hasAcceptedAgreements } = useAppSelector(state => state.agreement)
  const { isAppLoading } = useAppSelector(state => state.app)

  return (
    <div className="home">
      {
        !hasAcceptedAgreements && !isAppLoading && (
          <CguLicence />
        )
      }
      {
        hasAcceptedAgreements && !isAppLoading && (
        <Chat />
        )
      }
    </div>
  )
}

export default Home
