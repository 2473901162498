import { TextField } from '@mui/material'
import { ReactElement, useRef } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import SwitchMode from 'components/SwitchMode/SwitchMode'
import { ChatMode, MessageTypingAnimation, Stop } from 'reducers/chat/types'
import './SendInput.scss'

interface Props {
  message: string
  setMessage: (message: string) => void
  handleSend: () => void
  placeholder: string
  setChatMode: (mode: ChatMode) => void
  chatMode: ChatMode
  handleClickStopAnimation: () => void
  typingAnimation: MessageTypingAnimation
  isStopped: Stop | null
}

function SendInput({
  message,
  setMessage,
  handleSend,
  placeholder,
  setChatMode,
  chatMode,
  handleClickStopAnimation,
  typingAnimation,
  isStopped,
}: Props): ReactElement {
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSend()
    }
  }

  if (inputRef.current) {
    inputRef.current.focus()
  }

  return (
    <>
      <SwitchMode
        setChatMode={setChatMode}
        chatMode={chatMode}
        handleClickStopAnimation={handleClickStopAnimation}
        typingAnimation={typingAnimation}
        isStopped={isStopped}
      />
      <div className="input">
        <TextField
          inputRef={inputRef}
          className="textarea"
          size="medium"
          value={message}
          onChange={e => setMessage(e.target.value)}
          onKeyPress={onKeyPress}
          id="outlined-multiline-static"
          placeholder={placeholder}
          maxRows={7}
          multiline
          InputProps={{
            endAdornment: (
              <button
                type="button"
                onClick={handleSend}
                className="button"
              >
                <ArrowForwardIcon className="icon" />
              </button>
            ),
          }}
        />
      </div>
    </>
  )
}

export default SendInput
