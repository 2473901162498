import chatLogo from 'assets/logos/logo-chat.svg'
import terms from 'common/terms'
import FeedbackChat from 'components/FeedbackChat/FeedbackChat'
import { useAppDispatch, useAppSelector } from 'hooks'
import { ReactElement, useEffect, useState } from 'react'
import { setCurrentMessage } from 'reducers/chat/chat.reducers'
import { updateMessage } from 'reducers/chat/chat.thunk'
import {
  Message as MessageInterface, MessageTypingAnimation, Stop, UserTypes,
} from 'reducers/chat/types'
import { getDocumentById } from 'reducers/documents/document.thunk'
import { setSnackbar } from 'reducers/feedback/feedback.reducers'
import { SnackbarSeverity } from 'reducers/feedback/types'
import { getUserInitials, playTypingAnimation } from 'utils/functions'
import './Message.scss'
import MessageDocumentLink from './MessageDocumentLink/MessageDocumentLink'

interface Props {
  message: MessageInterface
  scrollToBottom: () => void
  setTypingAnimation: (typingAnimation: MessageTypingAnimation) => void
  hasScrollButton: boolean
  typingAnimation: MessageTypingAnimation
  isStopped: Stop | null
}

function Message({
  message,
  scrollToBottom,
  setTypingAnimation,
  hasScrollButton,
  typingAnimation,
  isStopped,
}: Props): ReactElement {
  const dispatch = useAppDispatch()
  const { account } = useAppSelector(state => state.user)
  const { isGettingHistory, currentMessage } = useAppSelector(state => state.chat)
  const [text, setText] = useState('')
  const [textStopped, setTextStopped] = useState('')
  const [isStoppedLocal, setIsStoppedLocal] = useState<boolean>(false)
  const isBot = message.sender === UserTypes.BOT

  useEffect(() => {
    if (!isBot) {
      setText(message.content)
    }
    dispatch(setCurrentMessage({
      id: message.id,
      text: message.content,
      sender: message.sender,
      exchangeId: message.conversationId,
    }))
  }, [message])

  useEffect(() => {
    if (!hasScrollButton) {
      scrollToBottom()
    }
  }, [text, hasScrollButton])

  useEffect(() => {
    if (isStopped && isStopped.id === message.id) {
      setIsStoppedLocal(true)
      setTextStopped(text)
      setTypingAnimation({
        id: currentMessage?.id || 0,
        isTypingAnimation: false,
      })
      if (textStopped) {
        dispatch(updateMessage({
          messageId: message.id,
          data: {
            content: textStopped || '',
          },
        }))
      }

      setCurrentMessage(null)
    }
  }, [isStopped, textStopped])

  const maxWidth = 300

  useEffect(() => {
    if (isGettingHistory) {
      setText(message.content)
    } else {
      playTypingAnimation(
        maxWidth,
        message.content,
        setText,
        isBot,
        setTypingAnimation,
        message.id,
      )
    }
  }, [])
  const logoToDisplay = () => {
    if (isBot) {
      return (
        <img
          src={chatLogo}
          alt={terms.Chat.Message.imgAlt}
          className="chat"
        />
      )
    }
    return (
      <span className="user">
        {getUserInitials(account)}
      </span>
    )
  }

  const handleClick = (id: number) => {
    dispatch(getDocumentById(id)).then(res => {
      if (res.payload) {
        window.open(res.payload.url, '_blank')
        dispatch(setSnackbar({
          severity: SnackbarSeverity.SUCCESS,
          message: terms.Document.Snackbar.downloadSuccess,
        }))
      }
    })
  }

  const shouldDisplayFeedback = isBot
  && (!typingAnimation.isTypingAnimation || isStopped)

  const shouldDisplayMessage = message.visible
  return (
    <div>
      {
      shouldDisplayMessage && (
        <div>
          <div
            className={`message ${isBot ? 'bot' : ''}`}
          >
            <div className="wrapper">
              <div className="logo">
                {logoToDisplay()}
              </div>
              <div className="response">
                {
                  isStoppedLocal ? (
                    <p className="content">
                      {textStopped}
                    </p>
                  ) : (
                    <p className="content">
                      {text}
                    </p>
                  )
                }
                <MessageDocumentLink
                  message={message}
                  handleClick={handleClick}
                  text={text}
                />
                {
              shouldDisplayFeedback && (
                <FeedbackChat message={message} />
              )
            }
              </div>
            </div>
          </div>
        </div>
      )
    }
    </div>

  )
}

export default Message
