import { ReactElement } from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { handleCopyMessage } from 'utils/functions'

import './CopyButton.scss'
import { TooltipLight } from 'components/Theme/TooltipLight'
import terms from 'common/terms'

interface Props {
  text: string
}

const CopyButton = ({ text }: Props): ReactElement => (
  <div className="copy">
    <TooltipLight
      title={terms.Chat.Message.copyButton}
      placement="top"
    >
      <button
        type="button"
        onClick={() => handleCopyMessage(text)}
        className="copy"
      >
        <ContentCopyIcon className="icon" />
      </button>
    </TooltipLight>

  </div>
)

export default CopyButton
