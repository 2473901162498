import CircularProgress from '@mui/material/CircularProgress'
import { ReactElement } from 'react'

import './customButton.scss'

export enum ButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
  filter = 'filter',
  black = 'black',
  blacktext = 'black-text',
  secondaryOutlined = 'secondary-outlined',
  secondaryText = 'secondary-text',
}

interface Props {
  title: string;
  handleClick: () => void;
  icon?: ReactElement;
  variant?: ButtonVariant;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
}

function CustomButton({
  title, handleClick, icon, variant, className, disabled, isLoading,
}: Props): ReactElement {
  return (
    <button
      disabled={disabled}
      type="button"
      className={`customButton ${variant && variant} ${className}`}
      onClick={handleClick}
    >
      {icon && (
      <div className="icon">
        {icon}
      </div>
      )}
      <span>{title}</span>
      {
        isLoading && (
          <div className="loader">
            <CircularProgress size="small" />
          </div>
        )
      }
    </button>
  )
}

CustomButton.defaultProps = {
  icon: undefined,
  variant: undefined,
  className: '',
  disabled: false,
  isLoading: false,
}

export default CustomButton
