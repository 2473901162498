import { auth } from '@osrdata/app_core'
import 'App.scss'
import {
  ReactElement, StrictMode, Suspense, useEffect, useState,
} from 'react'
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import AccessDenied from 'components/AccessDenied/AccessDenied'
import Loader from 'components/Loader/Loader'
import TabList from 'components/TabList/TabList'
import TopBar from 'components/TopBar/TopBar'
import { terms } from 'common/terms'
import navRoutes from 'utils/navRoutes'
import navTabElements from 'utils/navTabElements'
import { useAppDispatch, useAppSelector } from 'hooks'
import CustomSnackbar from 'components/Snackbar/Snackbar'
import {
  getAcceptedListAgreements,
  getAgreementlist,
  getHasAcceptedAgreements,
} from 'reducers/agreements/agreement.thunk'
import { hideSnackbar } from 'reducers/feedback/feedback.reducers'

const APP_NAME = 'ChatSNCF'
const ACCESS_PERM = 'chatsncf::ACCESS'

export default function App(): ReactElement {
  const dispatch = useAppDispatch()
  const { isLogged, appPermissions, isLoading } = useAppSelector(state => state.user)
  const { displaySnackbar, snackbarMessage, snackbarSeverity } = useAppSelector(state => state.feedback)
  const [accessDenied, setAccessDenied] = useState(false)

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
  }, [])

  useEffect(() => {
    if (isLogged && appPermissions.length > 0) {
      if (!appPermissions.includes(ACCESS_PERM)) {
        setAccessDenied(true)
      } else {
        dispatch(getHasAcceptedAgreements())
        dispatch(getAcceptedListAgreements())
        dispatch(getAgreementlist())
      }
    }
  }, [isLogged, appPermissions])

  if (!isLoading && !isLogged) return <div />

  return (
    <StrictMode>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <TopBar
            appName={APP_NAME}
            innerComponent={<TabList tabs={navTabElements} />}
            hasAccess={!isLoading && !accessDenied}
          />
          {(isLoading || !isLogged) && <div id="app"><Loader message={terms.Common.loading} /></div>}
          {!isLoading && (accessDenied ? <AccessDenied /> : (
            <div id="app">
              <Routes>
                {navRoutes.map(route => (
                  <Route element={<route.component />} path={route.path} key={route.path} />
                ))}
                <Route
                  path="*"
                  element={
                    <Navigate to={terms.Path.home.path} />
                  }
                />
              </Routes>
            </div>
          ))}
          <CustomSnackbar
            message={snackbarMessage}
            severity={snackbarSeverity}
            displaySnackbar={displaySnackbar}
            handleClose={() => dispatch(hideSnackbar())}
          />
        </BrowserRouter>
      </Suspense>
    </StrictMode>
  )
}
