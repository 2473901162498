import { TextField } from '@mui/material'
import {
  ReactElement, useEffect, useRef,
} from 'react'
import ClearIcon from '@mui/icons-material/Clear'

import './Search.scss'
import { DocumentTypes } from 'reducers/documents/types'

interface Props {
  placeholder: string
  handleSearch: (val: string, mode: DocumentTypes) => void
  size?: 'small' | 'medium'
  mode: DocumentTypes
  setSearchValue: (val: string) => void
  searchValue: string
}

const Search = ({
  placeholder, handleSearch, size, mode, setSearchValue, searchValue,
}: Props): ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setSearchValue(e.target.value)
    handleSearch(e.target.value, mode)
  }

  const handleClear = () => {
    setSearchValue('')
    handleSearch('', mode)
  }

  useEffect(() => {
    handleSearch('', mode)
  }, [mode])

  if (inputRef.current) {
    inputRef.current.focus()
  }

  return (
    <div className="search">
      <TextField
        className="input-text"
        inputRef={inputRef}
        size={size}
        value={searchValue}
        onChange={handleChange}
        id="outlined-multiline-static"
        placeholder={placeholder}
        InputProps={{
          endAdornment: (
            <div>
              {
                searchValue.length > 0 && (
                  <button
                    type="button"
                    onClick={handleClear}
                  >
                    <ClearIcon className="icon" />
                  </button>
                )
              }
            </div>

          ),
        }}
      />
    </div>
  )
}

Search.defaultProps = {
  size: 'medium',
}

export default Search
