import { ReactElement } from 'react'
import { Alert, Snackbar, SnackbarOrigin } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import './Snackbar.scss'

interface CustomSnackbarProps {
  message: string,
  displaySnackbar: boolean,
  handleClose: () => void,
  severity?: 'success' | 'info' | 'warning' | 'error' | undefined,
}

const anchorOriginSetups: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}

const styleSetups = {
  width: '100%',
}

function CustomSnackbar({
  message, displaySnackbar, handleClose, severity,
}: CustomSnackbarProps): ReactElement {
  const handleCloseAction = () => {
    handleClose()
  }
  return (
    <Snackbar
      id="custom-snackbar"
      open={displaySnackbar}
      onClose={handleCloseAction}
      autoHideDuration={3000}
      anchorOrigin={anchorOriginSetups}
      disableWindowBlurListener
    >
      <Alert
        action={(
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleCloseAction}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )}
        severity={severity}
        variant="filled"
        sx={styleSetups}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

CustomSnackbar.defaultProps = {
  severity: undefined,
}

export default CustomSnackbar
