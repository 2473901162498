import AddIcon from '@mui/icons-material/Add'
import terms from 'common/terms'
import CustomButton, { ButtonVariant } from 'components/CustomButton/CustomButton'
import { useAppDispatch, useAppSelector } from 'hooks'
import { ReactElement, useState } from 'react'
import { removeChat, setIsGettingHistory } from 'reducers/chat/chat.reducers'
import { createChat, getChatById, getChatList } from 'reducers/chat/chat.thunk'
import { Stop } from 'reducers/chat/types'
import ChatList from './ChatList/ChatList'
import DocumentButtons from './DocumentButtons/DocumentButtons'

import './Sidebar.scss'

interface Props {
  setOpenUploadDocument: () => void
  setOpenDocuments: () => void
  setIsStopped: (value: Stop | null) => void
}

function Sidebar({ setOpenUploadDocument, setOpenDocuments, setIsStopped }: Props): ReactElement {
  const dispatch = useAppDispatch()
  const { chatList, chat } = useAppSelector(state => state.chat)
  const [displaySidebar, setDisplaySidebar] = useState(true)
  const handleCreateChat = () => {
    dispatch(removeChat())
    dispatch(createChat())
    dispatch(getChatList())
    setIsStopped(null)
  }

  const handleClickChat = (id: number) => {
    dispatch(removeChat())
    dispatch(setIsGettingHistory(true))
    dispatch(getChatById(id))
    dispatch(getChatList())
    setIsStopped(null)
  }

  return (
    <>
      <button
        type="button"
        className={`button-sidebar ${displaySidebar ? 'close' : 'open'}`}
        onClick={() => setDisplaySidebar(!displaySidebar)}
      >
        <AddIcon className="icon" />
      </button>
      <div className={`sidebar ${displaySidebar ? 'visible' : 'hidden'}`}>
        <div className="new-chat">
          <CustomButton
            title={terms.Sidebar.Buttons.newChat}
            className="button"
            variant={ButtonVariant.secondary}
            icon={<AddIcon />}
            handleClick={handleCreateChat}
          />
        </div>
        <ChatList
          chatList={chatList}
          currentChatId={chat?.id || null}
          handleClickChat={handleClickChat}
        />
        <DocumentButtons
          setOpenUploadDocument={setOpenUploadDocument}
          setOpenDocuments={setOpenDocuments}
        />
      </div>
    </>
  )
}

export default Sidebar
