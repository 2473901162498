import { ReactElement } from 'react'
import './DocumentMode.scss'
import terms from 'common/terms'
import { DocumentTypes } from 'reducers/documents/types'

interface Props {
  mode: string
  handleClickMode: (search: string, mode: DocumentTypes) => void
}

const DocumentMode = ({ mode, handleClickMode }: Props): ReactElement => (
  <div className="doc-mode">
    <div>
      <button
        type="button"
        onClick={() => handleClickMode('', DocumentTypes.digidoc)}
        className={mode === DocumentTypes.digidoc ? 'selected' : ''}
      >
        {terms.Chat.SwitchMode.button.digidoc}
      </button>
    </div>
    <div>
      <button
        type="button"
        onClick={() => handleClickMode('', DocumentTypes.myDocuments)}
        className={mode === DocumentTypes.myDocuments ? 'selected' : ''}
      >
        {terms.Chat.SwitchMode.button.myDocuments}
      </button>
    </div>
  </div>
)

export default DocumentMode
