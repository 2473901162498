import {
  FormControl, FormLabel, TextField, SelectChangeEvent,
} from '@mui/material'
import terms from 'common/terms'
import CustomDropZone from 'components/CustomDropZone/CustomDropZone'
import CustomSelect from 'components/CustomSelect/CustomSelect'
import { ReactElement } from 'react'
import { FeedbackAppEnum } from 'reducers/feedback/types'

interface Props {
  typeInput: FeedbackAppEnum | string;
  handleChangeSelect: (event: SelectChangeEvent<string>) => void;
  descriptionInput: string;
  setDescriptionInput: (value: string) => void;
  imageInput: File | null;
  setImageInput: (value: File | null) => void;
}

const FeedbackAppInputs = ({
  typeInput,
  handleChangeSelect,
  descriptionInput,
  setDescriptionInput,
  imageInput,
  setImageInput,
}: Props): ReactElement => {
  const feedbackType = Object.values(FeedbackAppEnum)
  const acceptedTypes = {
    'image/png': ['png'],
    'image/jpeg': ['jpeg'],
    'image/jpg': ['jpg'],
  }

  return (
    <div className="inputs">
      <CustomSelect
        inputValue={typeInput}
        handleChange={handleChangeSelect}
        placeholder={terms.Feedback.App.placeholder.type}
        label={terms.Feedback.App.labels.type}
        options={feedbackType}
        required
      />
      <FormControl fullWidth className="textarea-input">
        <FormLabel required>
          {terms.Feedback.App.labels.description}
        </FormLabel>
        <TextField
          multiline
          placeholder={terms.Feedback.App.placeholder.description}
          rows={4}
          variant="outlined"
          value={descriptionInput}
          onChange={e => setDescriptionInput(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel>
          {terms.Feedback.App.labels.screenshot}
        </FormLabel>
        <CustomDropZone
          isUploading={false}
          maxFiles={1}
          acceptedTypes={acceptedTypes}
          onDropAccepted={(files: File[]) => setImageInput(files[0])}
          fileSelected={imageInput}
          setFileSelected={setImageInput}
          label={terms.Feedback.App.labels.dropzone}
          variant="blue"
          className="dropzone-app"
          imageMode
        />
      </FormControl>
    </div>
  )
}

export default FeedbackAppInputs
