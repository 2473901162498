import { Rating } from '@mui/material'
import { ReactElement, useState } from 'react'
import StarIcon from '@mui/icons-material/Star'

import './CustomRating.scss'

interface Props {
  label: string
  id: string
  handleChangeRating: (val: number, id: string) => void
}

const CustomRating = ({ label, handleChangeRating, id }: Props): ReactElement => {
  const [rating, setRating] = useState<number>(0)
  return (
    <div className="rating">
      <div className="label">
        {label}
      </div>
      <div>
        <Rating
          className="rating-stars"
          name="simple-controlled"
          size="large"
          value={rating}
          onChange={(_, newValue) => {
            setRating(newValue as number)
            handleChangeRating(newValue as number, id)
          }}
          emptyIcon={<StarIcon className="icon" fontSize="inherit" />}
        />
      </div>
    </div>
  )
}

export default CustomRating
