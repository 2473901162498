import { TextField } from '@mui/material'
import terms from 'common/terms'
import CustomButton, { ButtonVariant } from 'components/CustomButton/CustomButton'
import ModalWrapper from 'components/ModalWrapper/ModalWrapper'
import { useAppDispatch } from 'hooks'
import { ReactElement, useState } from 'react'
import { getUserFeedbacks, sendFeedbackNotification } from 'reducers/feedback/feedback.thunk'

import './FeedbackNotification.scss'

interface Props {
  open: boolean
  question: string
}

const FeedbackNotification = ({ open, question }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const [answer, setAnswer] = useState<string>('')

  const handleClickSend = () => {
    dispatch(sendFeedbackNotification({
      question,
      answer,
    })).then(() => {
      setAnswer('')
      dispatch(getUserFeedbacks())
    })
  }
  return (
    <ModalWrapper
      open={open}
      closable={false}
      title={terms.Feedback.Notification.title}
    >
      <div className="feedback-notification">
        <div className="label">
          {question}
        </div>
        <div className="answer">
          <TextField
            fullWidth
            multiline
            rows={6}
            variant="outlined"
            value={answer}
            onChange={e => setAnswer(e.target.value)}
          />
        </div>
        <div className="buttons">
          <div>
            <CustomButton
              variant={ButtonVariant.primary}
              handleClick={handleClickSend}
              title={terms.Common.send}
            />
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default FeedbackNotification
