import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import terms from 'common/terms'
import { setSnackbar } from 'reducers/feedback/feedback.reducers'
import { SnackbarSeverity } from 'reducers/feedback/types'
import { getOnDownloadProgressHandler } from 'utils/functions'
import { addMessage } from './chat.reducers'
import {
  Message, FeedbackChat, ParamsSendMessage, UpdateMessage,
} from './types'

const getChatList = createAsyncThunk(
  'chat/getChatList',
  async (_, thunkAPI) => {
    try {
      const response = await get('/pythie/conversations/')

      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const getChatById = createAsyncThunk(
  'chat/getChatById',
  async (id: number, thunkAPI) => {
    try {
      const response = await get(`/pythie/conversations/${id}/`)
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

// eslint-disable-next-line import/no-mutable-exports
export let controller = new AbortController()

const sendMessage = createAsyncThunk(
  'chat/sendMessage',
  async (params: ParamsSendMessage, thunkAPI) => {
    try {
      controller = new AbortController()
      const { signal } = controller
      const dispatchAddMessage = (response: Message) => thunkAPI.dispatch(addMessage(response))
      const dispatchSnackbarError = () => thunkAPI.dispatch(setSnackbar({
        message: terms.Common.errorMessages.internalServerError,
        severity: SnackbarSeverity.ERROR,
      }))
      await post(
        `/pythie/conversations/${params.id}/messages`,
        params.message,
        {
          params: { output: 'stream' },
          onDownloadProgress: getOnDownloadProgressHandler(dispatchAddMessage, dispatchSnackbarError),
          signal,
        },
      )
      return null
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const createChat = createAsyncThunk(
  'chat/createChat',
  async (_, thunkAPI) => {
    try {
      const response = await post('/pythie/conversations/', {})
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const deleteChat = createAsyncThunk(
  'chat/deleteChat',
  async (conversationId: number, thunkAPI) => {
    try {
      const response = await deleteRequest(`/pythie/conversations/${conversationId}/`)
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const getTags = createAsyncThunk(
  'chat/getTags',
  async (_, thunkAPI) => {
    try {
      const response = await get('/pythie/tags/')
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const sendFeedback = createAsyncThunk(
  'chat/sendFeedback',
  async (params: FeedbackChat, thunkAPI) => {
    try {
      const response = await post(`/pythie/messages/${params.messageId}/feedback`, params)
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const updateFeedback = createAsyncThunk(
  'chat/updateFeedback',
  async (params: FeedbackChat, thunkAPI) => {
    try {
      const response = await patch(`/pythie/messages/${params.messageId}/feedback`, params)
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const updateMessage = createAsyncThunk(
  'chat/updateMessage',
  async (params: UpdateMessage, thunkAPI) => {
    try {
      const response = await patch(
        `/pythie/messages/${params.messageId}`,
        params.data,
      )
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export {
  getChatList,
  getChatById,
  createChat,
  sendMessage,
  deleteChat,
  getTags,
  sendFeedback,
  updateFeedback,
  updateMessage,
}
