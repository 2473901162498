import { TextField } from '@mui/material'
import terms from 'common/terms'
import CustomButton, { ButtonVariant } from 'components/CustomButton/CustomButton'
import CustomRating from 'components/CustomRating/CustomRating'
import ModalWrapper from 'components/ModalWrapper/ModalWrapper'
import { useAppDispatch, useAppSelector } from 'hooks'
import { ReactElement, useEffect, useState } from 'react'
import { sendFeedbackRating } from 'reducers/feedback/feedback.thunk'
import { Rating } from 'reducers/feedback/types'

import './FeedbackRating.scss'

interface Props {
  open: boolean
  handleClose: () => void
}

const FeedbackRating = ({ open, handleClose }: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const { ratings } = useAppSelector(state => state.feedback)
  const [ratingsInput, setRatingsInput] = useState<Rating[]>([])
  const [descriptionInput, setDescriptionInput] = useState('')

  useEffect(() => {
    setRatingsInput(ratings)
  }, [ratings])

  const handleChangeRating = (val: number, id: string) => {
    const newRatings = ratingsInput.map(rating => {
      if (rating.id === id) {
        return {
          ...rating,
          stars: val,
        }
      }
      return rating
    })
    setRatingsInput(newRatings)
  }

  const handleClickClose = () => {
    setDescriptionInput('')
    setRatingsInput([])
    handleClose()
  }

  const handleClickSend = () => {
    dispatch(sendFeedbackRating({
      ratings: ratingsInput.map(rating => ({ stars: rating.stars, description: rating.description })),
      comment: descriptionInput,
    })).then(() => handleClickClose())
  }

  return (
    <ModalWrapper
      title={terms.Feedback.Rating.title}
      open={open}
      closable={false}
    >
      <div className="feedback-rating">
        <div className="ratings">
          {
            ratings.map(rating => (
              <CustomRating
                key={rating.id}
                id={rating.id}
                label={rating.description}
                handleChangeRating={handleChangeRating}
              />
            ))
          }
          <div className="description">
            <div className="label">
              {terms.Feedback.Rating.labels.description}
            </div>
            <TextField
              fullWidth
              multiline
              rows={2}
              variant="outlined"
              value={descriptionInput}
              onChange={e => setDescriptionInput(e.target.value)}
            />
          </div>
        </div>
        <div className="buttons">
          <div>
            <CustomButton
              title={terms.Common.send}
              variant={ButtonVariant.secondary}
              handleClick={handleClickSend}
            />
          </div>

        </div>

      </div>
    </ModalWrapper>
  )
}

export default FeedbackRating
