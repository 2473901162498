// ENUMS

export enum UserTypes {
  USER = 'user',
  BOT = 'assistant',
}

export enum ChatMode {
  chat = 'chat',
  myDocuments = 'user',
  digidoc = 'documents'
}

export enum Thumbs {
  up = 'up',
  down = 'down',
}

// TYPES

export type ChatState = {
  chat: Chat | null,
  chatList: ChatSummary[],
  isWaiting: boolean,
  isGettingHistory: boolean,
  currentMessage: {
    id: number,
    exchangeId: number,
    text: string,
    sender: string,
  } | null,
  tags: Tags[],
}

export type ChatSummary = {
  id: number,
  summary: string,
  timestamp: string,
}

export type Chat = {
  id: number,
  messages: Message[],
  timestamp: string,
}

export type Message = {
  id: number,
  conversationId: number,
  exchangeId: number,
  mode: string,
  kind: string,
  sender: string,
  finishReason: string,
  content: string,
  timestamp: string,
  textChunks: TextChunk[],
  feedback: FeedbackChat | null,
  visible: boolean,
}

export type TextChunk = {
  id: number,
  filename: string,
  source: string,
  page: number,
  subPage: number,
  content: string,
  documentId: number,
}

export type ParamsSendMessage = {
  id: number,
  message: {
    content: string,
    mode: string,
  },
}

export type Tags = {
  name: string,
}

export type FeedbackChat = {
  messageId: number,
  feedbackType: Thumbs | string,
  tags: Tags[],
  comment: string,
  answerProposal: string | null,
}

export type MessageTypingAnimation = {
  id: number | null;
  isTypingAnimation: boolean;
}

export type UpdateMessage = {
  messageId: number;
  data: {
    content: string;
  }
}

export type Stop = {
  isStopped: boolean
  id: number
}
