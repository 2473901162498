import { ReactElement } from 'react'

import './Waiting.scss'

interface Props {
  waitingMessage?: string
  color?: string
  fontSize?: string
}

function Waiting({ waitingMessage, color, fontSize }: Props): ReactElement {
  return (
    <div className="waiting">
      <div className="wrapper">
        {
          waitingMessage && (
            <div className="text" style={{ color, fontSize }}>
              {waitingMessage}
            </div>
          )
        }
        <div className="dot-pulse" />
      </div>
    </div>
  )
}

Waiting.defaultProps = {
  waitingMessage: '',
  color: '',
  fontSize: '',
}

export default Waiting
