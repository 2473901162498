import { get, post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Acceptance } from './types'

const getAgreementlist = createAsyncThunk(
  'agreement/getAgreementlist',
  async (_, thunkApi) => {
    try {
      const response = await get('/pythie/agreements/')
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getAcceptedListAgreements = createAsyncThunk(
  'agreement/getAcceptedListAgreements',
  async (_, thunkApi) => {
    try {
      const response = await get('/pythie/acceptances/')
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getHasAcceptedAgreements = createAsyncThunk(
  'agreement/getHasAcceptedAgreements',
  async (_, thunkApi) => {
    try {
      const response: {accepted: boolean} = await get('/pythie/has-accepted-legal/')
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const sendAgreement = createAsyncThunk(
  'agreement/postAcceptAgreement',
  async (params: Acceptance, thunkApi) => {
    try {
      const response = await post('/pythie/acceptances/', params)
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export {
  getAgreementlist, getHasAcceptedAgreements, sendAgreement, getAcceptedListAgreements,
}
