import { ReactElement, RefObject } from 'react'
import logoDgex from 'assets/logos/logo1.png'
import logoPdi from 'assets/logos/logo2.png'

import './cguWrapper.scss'
import CustomButton, { ButtonVariant } from 'components/CustomButton/CustomButton'

interface Props {
  buttonLabel: string
  children: ReactElement
  handleClick: () => void
  isLoading: boolean
  cguRef: RefObject<HTMLDivElement>
}

function CGUWrapper({
  buttonLabel, children, handleClick, isLoading, cguRef,
}: Props): ReactElement {
  return (
    <div className="cgu" ref={cguRef}>
      <div className="container">
        <div className="header">
          <div className="images">
            <img className="dgex" src={logoPdi} alt="pdi" />
            <img className="pdi" src={logoDgex} alt="dgexsol" />
          </div>
        </div>

        <div>
          {children}
        </div>
        <div className="button">
          <CustomButton
            title={buttonLabel}
            handleClick={handleClick}
            variant={ButtonVariant.primary}
            isLoading={isLoading}
          />
        </div>
      </div>

    </div>
  )
}

export default CGUWrapper
