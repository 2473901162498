import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import CircleDownIcon from '@mui/icons-material/ExpandCircleDownOutlined'
import { Message as MessageInterface, MessageTypingAnimation, Stop } from 'reducers/chat/types'
import terms from 'common/terms'
import { TooltipLight } from 'components/Theme/TooltipLight'
import { getMessagesToDisplay } from 'utils/functions'
import Message from './Message/Message'

interface Props {
  messages: MessageInterface[] | [];
  isStopped: Stop | null;
  typingAnimation: MessageTypingAnimation;
  setTypingAnimation: (typingAnimation: MessageTypingAnimation) => void;
}

function MessageList({
  messages, isStopped, typingAnimation, setTypingAnimation,
}: Props): ReactElement {
  const messageListRef = useRef<HTMLDivElement>(null)
  const [displayScrollButton, setDisplayScrollButton] = useState<boolean>(false)

  const [messagesDisplayed, setMessagesDisplayed] = useState<MessageInterface[]>(messages)

  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = 0
    }
  }

  const handleScroll = () => {
    const isAtBottom = messageListRef.current?.scrollTop === 0
    setDisplayScrollButton(!isAtBottom)
  }

  useEffect(() => {
    setMessagesDisplayed(messages)
  }, [messages])

  useEffect(() => {
    if (messageListRef.current) {
      const { scrollTop } = messageListRef.current
      setDisplayScrollButton(scrollTop < 0)
    }
  }, [messageListRef.current?.scrollTop])

  useEffect(() => {
    getMessagesToDisplay(
      messages,
      typingAnimation,
      setMessagesDisplayed,
    )
  }, [typingAnimation.isTypingAnimation, messages])

  if (!messages) return <div />

  const reverseMessages = [...messagesDisplayed].reverse()
  return (
    <div
      className="messages"
      ref={messageListRef}
      onScroll={handleScroll}
    >
      {reverseMessages.map(item => (
        <Message
          key={item.id}
          message={item}
          scrollToBottom={scrollToBottom}
          setTypingAnimation={setTypingAnimation}
          hasScrollButton={displayScrollButton}
          typingAnimation={typingAnimation}
          isStopped={isStopped}
        />
      ))}
      {displayScrollButton && (
        <div className="scroll-button">
          <TooltipLight
            title={terms.Chat.buttonScrollToBottom}
            placement="top"
          >
            <button
              type="button"
              onClick={() => {
                scrollToBottom()
                setDisplayScrollButton(false)
              }}
            >
              <CircleDownIcon />
            </button>
          </TooltipLight>
        </div>
      )}
    </div>
  )
}

export default MessageList
