/* eslint-disable max-len */
export const terms = {
  Common: {
    loading: 'Chargement',
    cancel: 'Annuler',
    confirm: 'Confirmer',
    delete: 'Supprimer',
    save: 'Enregistrer',
    close: 'Fermer',
    validate: 'Valider',
    send: 'Envoyer',
    errorMessages: {
      forbidden: 'Vous n\'avez pas les droits pour effectuer cette action.',
      notFound: 'La ressource demandée n\'existe pas.',
      internalServerError: 'Une erreur est survenue.',
      serverDown: 'Le serveur est indisponible.',
      unknownError: 'Une erreur inconnue est survenue.',
      badRequest: 'Vérifiez les informations que vous avez entrées et réessayez.',
      videoTag: 'Votre navigateur ne supporte pas la vidéo.',
    },
    topbarTag: 'Expérimentation',
    topbarEngineering: {
      content: 'Propulsé par',
      title: 'MISTRAL',
      subTitle: 'AI_',
    },
  },
  Error: {
    forbidden: {
      appTitle: 'Vous n\'avez pas les droits nécessaires pour accéder à cette application.',
      appSubtitle: 'Veuillez contacter votre administrateur.',
      content: 'Vous n\'avez pas les droits pour accéder à cette ressource. Veuillez réessayer ou contacter votre administrateur.',
      exitButton: 'Retour au portail des applications',
    },
  },
  Path: {
    home: {
      title: 'Chat',
      path: '/chat/',
    },
    cgu: {
      title: 'Conditions générales',
      path: '/conditions-generales/',
    },
  },

  Licence: {
    button: {
      cgu: 'Accepter les Conditions générales d\'utilisation',
      chart: 'Accepter la Charte d\'expérimentation',
    },
    Snackbar: {
      createSuccess: 'Votre acceptation a bien été prise en compte.',
    },
  },
  Chat: {
    Input: {
      placeholder: 'Envoyer un message',
    },
    Message: {
      imgAlt: 'avatar du chat-SNCF',
      stopButton: 'Arrêter la lecture',
      copySuccess: 'Message copié dans le presse-papier',
      copyError: 'Erreur lors de la copie du message',
      copyButton: 'Copier',
    },
    waitingMessage: 'Chat-SNCF est en train d\'écrire',
    Snackbar: {
      createSuccess: 'Nouvelle conversation créée.',
      deleteSuccess: 'Conversation supprimée.',
    },
    SwitchMode: {
      info: 'Faire une recherche documentaire',
      label: 'Je recherche dans',
      button: {
        myDocuments: 'Mes documents',
        digidoc: 'Digidoc',
      },
      or: 'ou',
      page: 'Page :',
    },
    buttonScrollToBottom: 'Défiler vers le bas',
    Feedback: {
      question: 'Pourquoi avez-vous choisi cette notation ?',
      Inputs: {
        comment: 'Commentaire',
        response: 'Suggestion de réponse',
        send: 'Envoyer',
      },
      myDocuments: {
        alertMessage: {
          oneDocument: '*Attention un document est encore en cours de traitement.',
          severalDocuments: (nbDoc: number) => `*Attention ${nbDoc} documents sont encore en cours de traitement.`,
        },
      },

      Snackbar: {
        sendSuccess: 'Votre commentaire a bien été envoyé.',
        updateSuccess: 'Votre commentaire a bien été mis à jour.',
      },
    },
  },

  Sidebar: {
    Buttons: {
      newChat: 'Nouveau chat',
      uploadDocument: 'Importer un document',
      documents: 'Bibliothèque documentaire',
    },
  },

  Banner: {
    label: 'ChatSNCF est actuellement en phase de test',
    button: 'Je remonte un problème, une remarque, un bug',
  },

  Document: {
    Snackbar: {
      uploadSuccess: 'Votre document a bien été importé, un traitement est maintenant en cours.',
      deleteSuccess: 'Votre document a bien été supprimé.',
      downloadSuccess: 'Votre document a bien été téléchargé.',
    },
    Status: {
      PENDING: 'En attente de traitement',
      STARTED: 'En cours de traitement',
      SUCCESS: 'Traitement terminé',
      FAILURE: 'Échec du traitement',
    },
    Upload: {
      title: 'Importer un document',
      dragFile: 'Glissez-déposez le fichier ou',
      selectFile: 'Sélectionnez le fichier sur votre ordinateur',
      uploadPending: {
        title: 'Fichier en cours d\'envoi.',
        info: 'Cela peut prendre quelques minutes si le fichier est volumineux.',
        message: 'Veuillez ne pas rafraichir la page.',
      },
      traitement: {
        success: 'Votre document a bien été traité.',
        error: 'Une erreur est survenue lors du traitement de votre document.',
      },
      error: {
        invalidType: 'Le type de fichier n\'est pas autorisé.',
        tooManyFiles: 'Vous ne pouvez envoyer que 10 fichiers à la fois.',
        fileTooLarge: 'Le fichier est trop volumineux. 20Mo maximum.',
      },
    },
    List: {
      title: 'Bibliothèque documentaire',
    },
    Search: {
      placeholder: 'Rechercher un document',
      button: 'Importer un document',
    },
  },
  Feedback: {
    App: {
      title: 'Un problème, une remarque, un bug ?',
      placeholder: {
        type: 'Sélectionnez un type',
        description: 'Décrivez votre problème',
      },
      labels: {
        type: 'Type',
        description: 'Description',
        screenshot: 'Capture d\'écran',
        dropzone: 'Glissez-déposez votre image ici',
      },
      snackbars: {
        success: 'Votre message a bien été envoyé.',
        error: 'Une erreur est survenue lors de l\'envoi de votre message.',
      },
    },
    Rating: {
      title: 'Comment évaluez-vous cette application ?',
      labels: {
        description: 'Qu\'est-ce qui pourrait être amélioré ?',
      },
      snackbars: {
        success: 'Votre évaluation a bien été envoyée.',
        error: 'Une erreur est survenue lors de l\'envoi de votre évaluation.',
      },

    },
    Notification: {
      title: 'Nous avons besoin de votre avis.',
      snackbars: {
        success: 'Votre réponse a bien été envoyée.',
        error: 'Une erreur est survenue lors de l\'envoi de votre réponse.',
      },
    },
  },
} as const

export default terms
