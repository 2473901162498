import { ReactElement } from 'react'
import { ChatSummary } from 'reducers/chat/types'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { useAppDispatch } from 'hooks'
import { deleteChat } from 'reducers/chat/chat.thunk'

interface Props {
  chatList: ChatSummary[]
  handleClickChat: (id: number) => void
  currentChatId: number | null
}

function ChatList({ chatList, handleClickChat, currentChatId }: Props): ReactElement {
  const dispatch = useAppDispatch()
  const handleDelete = (id: number) => {
    dispatch(deleteChat(id))
  }

  return (
    <div className="chat-list">
      {
        chatList.map(chat => (
          <div key={chat.id} className="conversation">
            <button
              type="button"
              className={`button ${currentChatId === chat.id ? 'active' : ''}`}
              onClick={() => handleClickChat(chat.id)}
            >
              <ChatBubbleOutlineIcon className="icon" />
              <span className="content">{chat.summary}</span>
            </button>
            <button type="button" className="delete-button" onClick={() => handleDelete(chat.id)}>
              <DeleteForeverIcon className="icon" />
            </button>
          </div>
        ))
      }

    </div>
  )
}

export default ChatList
