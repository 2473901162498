import { ReactElement } from 'react'
import Home from 'pages/home/Home'
import terms from 'common/terms'

export type NavRoute = {
  title: string
  path: string
  component: () => ReactElement
}

const navRoutes: NavRoute[] = [
  {
    title: terms.Path.home.title,
    path: terms.Path.home.path,
    component: Home,
  },
]

export default navRoutes
