import { ReactElement } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Message as MessageInterface } from 'reducers/chat/types'
import terms from 'common/terms'

interface Props {
  message: MessageInterface
  handleClick: (documentId: number) => void
  text: string
}

const MessageDocumentLink = ({ message, handleClick, text }: Props): ReactElement => (
  <div className="links">
    { text.length === message.content.length
    && message.textChunks?.map(item => (
      <>
        <button
          type="button"
          key={uuidv4()}
          onClick={() => handleClick(item.documentId)}
        >
          <div />
          <span className="link-content">
            {item.filename}
          </span>
          <span className="page">
            {`${terms.Chat.SwitchMode.page} ${item.page}`}
          </span>
        </button>
        <div className="resume">
          {`"${item.content}"`}
        </div>
      </>
    ))}
  </div>
)

export default MessageDocumentLink
