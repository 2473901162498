export interface NavTabElement {
  icon?: string,
  title: string,
  path: string,
  role: string[],
}

const navTabElements: NavTabElement[] = [
]

export default navTabElements
